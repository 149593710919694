<template>
	<transition name="slide">
		<div id="pane-report" class="pane pane-report report" v-if="app.view == 'report'" :class="{vi : app.aide_live}">
		<!--
			<div v-if="app.export_progress_visible">
				<div class="progress-bar">
					<div class="progress-bar-fill" :style="{ width: app.export_progress + '%' }"></div>
				</div>
			</div>
		-->	
		<transition name="slide-right">
		<div id="exportPdf" v-show="exportPdfVisible" style="color: #333333; z-index: 1999;overflow: auto;position: absolute; width:750px; background-color: white; right: 0px;top:126px; padding: 10px; border-left: 1px solid #CCCCCC;border-bottom: 1px solid #cccc;height: 100%;">
			<div style="display: flex;justify-content: space-between;line-height: 48px;vertical-align: middle;">
				<div>
					Personnalisez votre étude
				</div>
				<div style="width:48px;height:48px;vertical-align: middle;text-align: center;">
					<div class="close" @click="exportPdfVisible=false"><icon file="x" :size="32" /></div>
				</div>
			</div>
			<div class="middle_center">
				<ul class="surface-card p-2 m-0 list-none flex overflow-x-auto select-none" style="width: max-content;">
					<li class="pr-3">
						<a v-ripple class="cursor-pointer px-4 py-3 flex align-items-center hover:surface-100 border-round transition-colors transition-duration-150 p-ripple" 
							:class="{'bg-primary hover:bg-primary': modele == 'standard', 'text-700': modele != 'standard'}" @click="modele='standard'">
							<i class="pi mr-2"></i>
							<span class="font-medium">{{ $t('imprimer_en_pdf') }}</span>
						</a>
					</li>
					<li class="flex align-items-center" v-if="app.user.adequation">
						<div style="width:1px; height: 50%" class="border-right-1 surface-border"></div>
					</li>
					<li class="px-3" v-if="app.user.adequation">
						<a v-ripple class="cursor-pointer px-4 py-3 flex align-items-center hover:surface-100 border-round transition-colors transition-duration-150 p-ripple" 
							:class="{'bg-primary hover:bg-primary': modele == 'flash', 'text-700': modele != 'flash'}" @click="modele='flash'">
							<i class="pi mr-2"></i>
							<span class="font-medium">{{ $t('PDF modèle Avis Flash') }}</span>
						</a>
					</li>
					<li class="flex align-items-center">
						<div style="width:1px; height: 50%" class="border-right-1 surface-border"></div>
					</li>
					<li class="px-3">
						<a v-ripple class="cursor-pointer px-4 py-3 flex align-items-center hover:surface-100 border-round transition-colors transition-duration-150 p-ripple" 
							:class="{'bg-primary hover:bg-primary': modele == 'lli', 'text-700': modele != 'locative'}" @click="modele='lli'">
							<i class="pi mr-2"></i>
							<span class="font-medium">{{ $t('Etude Locative') }}</span>
						</a>
					</li>
				</ul>
			</div>

			<div class="wrapper-scroll"  style="max-height: calc(100vh - 310px); overflow: auto;">	
				<br><br>
						<span class="p-float-label" style="width:100%">
							<InputText style="width:100%" id="id_etude_titre" type="text" v-model="etude_titre" />
							<label for="id_etude_titre">Titre de votre étude</label>
						</span>
						<br>
							<draggable :disabled="modele=='lli'" v-model="items" class="list" @end="onDragEnd" handle=".drag-handle" ghost-class="drag-ghost" animation="200" :class="{inactive : modele=='lli'}">
								<div v-for="(item) in items" :key="item.id" class="list-item">
									<div style="display:flex; line-height: 28px;vertical-align: middle;justify-content: space-between;">
										<div style="display:flex; line-height: 28px;vertical-align: middle;">
											<div class="drag-handle">⋮⋮</div>

											<div>{{ item.name }}</div>
										</div>
										<!-- InputSwitch -->
										<div class="input-switch">
											<label>Actif</label>
											<InputSwitch v-model="item.actif" />
										</div>
									</div>
									<!-- Name -->

									<!-- Sous-items (PrimeVue Chips) -->
									<div v-if="item['sousItems'] && item['sousItems'].length" class="chips">
									<div
										v-for="subItem in item['sousItems']"
										@click="inverse(subItem.parent_id, subItem.id)"
										:key="subItem.parent_id + '-' + subItem.id"
										:label="subItem.name"
										class="chip"
										:class="{chip_active: subItem.actif}"
									>{{subItem.name}}<span class="close-btn" v-if="subItem.actif">✖</span></div>
									</div>
								</div>
							</draggable>
						<br>
						
						<!--
						<h3>Commentaires</h3>
						<Textarea v-model="comment" rows="5" cols="30" style="width:100%" autoResize />
						-->
						<!--
						<Editor v-model="comment">
							<template #toolbar>
								<span class="ql-formats">
									<select class="ql-font"></select>
									<select class="ql-size"></select>
								</span>
								<span class="ql-formats">
									<button class="ql-bold"></button>
									<button class="ql-italic"></button>
									<button class="ql-underline"></button>
									<button class="ql-strike"></button>
								</span>
								<span class="ql-formats">
									<select class="ql-color"></select>
									<select class="ql-background"></select>
								</span>
								<span class="ql-formats">
									<button class="ql-script" value="sub"></button>
									<button class="ql-script" value="super"></button>
								</span>
								<span class="ql-formats">
									<button class="ql-header" value="1"></button>
									<button class="ql-header" value="2"></button>
									<button class="ql-blockquote"></button>
									<button class="ql-code-block"></button>
								</span>
								<span class="ql-formats">
									<button class="ql-list" value="ordered"></button>
									<button class="ql-list" value="bullet"></button>
									<button class="ql-indent" value="-1"></button>
									<button class="ql-indent" value="+1"></button>
								</span>
								<span class="ql-formats">
									<button class="ql-direction" value="rtl"></button>
									<select class="ql-align"></select>
								</span>
							</template>
						</Editor>
						-->
						<br><br>
						<button type="button" class="p-splitbutton-defaultbutton p-button p-component" style="width:300px;border-radius: 30px;" @click="exportPdfVisible = false; get_pdf_perso();">
							<span class="p-button-label">{{ $t('Imprimer') }}</span>
						</button>
						<br>
					<br>
				</div>
			</div>
		</transition>

			<div v-show="choix_report && false" class="dropdown">
				<div style="width:100%; display:flex;justify-content: center;padding-top: 10px;">
					<div class="p-splitbutton p-component" style="width:300px;">
						<button type="button" class="p-splitbutton-defaultbutton p-button p-component" style="border-top-right-radius: 0;border-bottom-right-radius: 0; width:80%;border-bottom-left-radius: 30px;border-top-left-radius: 30px;" @click="choixmodule=false;get_pdf(true, 'standard');">
							<span class="p-button-label">{{ $t('imprimer_en_pdf') }}</span>
							<span class="p-ink" style="height: 88px; width: 88px; top: -21.75px; left: 25.7656px;"></span>
						</button>
						<button type="button" class="p-splitbutton-menubutton p-button p-component p-button-icon-only" style="border-top-left-radius: 0;border-bottom-left-radius: 0; width:20%; border-left-color: white;border-bottom-right-radius: 30px;border-top-right-radius: 30px;" @click="choixmodule = !choixmodule">
							<span class="pi pi-cog p-button-icon"></span>
							<span class="p-button-label">&nbsp;</span>
							<span class="p-ink" style="height: 42px; width: 42px; top: 2.25px; left: 5.48438px;"></span>
						</button>
					</div>
				</div>
				<div v-if="choixmodule" style="padding-top: 10px;padding-bottom: 10px;">
					<div style="width:100%; display:flex;justify-content: space-between;padding-top: 10px;">
						<div>{{ $t('module_du_neuf') }}</div>
						<div style="padding-left: 20px">
							<InputSwitch v-model="choix_module.neuf" :disabled="app.livedata.synoptique.length<2" @change="onChangeModules('neuf')" />
						</div>
					</div>
					<div style="width:100%; display:flex;justify-content: space-between;padding-top: 10px;">
						<div>{{ $t('module_des_residences_de_services') }}</div>
						<div style="padding-left: 20px">
							<InputSwitch v-model="choix_module.rs" :disabled="!app.livedata.synoptique.length" @change="onChangeModules('rs')" />
						</div>
					</div>
					<div style="width:100%; display:flex;justify-content: space-between;padding-top: 10px;">
						<div>{{ $t('module_de_lancien') }}</div>
						<div style="padding-left: 20px">
							<InputSwitch v-model="choix_module.ancien" :disabled="!app.livedata.synoptique.length" @change="onChangeModules('ancien')" />
						</div>
					</div>
					<div style="width:100%; display:flex;justify-content: space-between;padding-top: 10px;">
						<div>{{ $t('module_du_locatif') }}</div>
						<div style="padding-left: 20px">
							<InputSwitch v-model="choix_module.locatif" :disabled="!app.livedata.synoptique.length" @change="onChangeModules('locatif')" />
						</div>
					</div>
					<div style="width:100%; display:flex;justify-content: space-between;padding-top: 10px;">
						<div>{{ $t('module_qualite_dadresse') }}</div>
						<div style="padding-left: 20px">
							<InputSwitch v-model="choix_module.adresse" :disabled="!app.livedata.synoptique.length" @change="onChangeModules('adresse')" />
						</div>
					</div>
				</div>

				<div style="width:100%; display:flex;justify-content: center;padding-top: 10px;" v-if="app.user.adequation">
					<button type="button" class="p-splitbutton-defaultbutton p-button p-component" style="width:300px;border-radius: 30px;" @click="choixmodule=false;get_pdf(true, 'flash');">
						<span class="p-button-label">{{ $t('PDF modèle Avis Flash') }}</span>
					</button>
				</div>

				<div style="width:100%; display:flex;justify-content: center;padding-top: 10px;">
					<button type="button" class="p-splitbutton-defaultbutton p-button p-component" style="width:300px;border-radius: 30px;" @click="choixmodule=false;get_pdf(true, 'lli');">
						<span class="p-button-label">{{ $t('Etude locative') }}</span>
					</button>
				</div>

			</div>
			
			<div class="infinite-loader" v-if="app.loading_calc">
				<div class="loader"><icon file="loader" :size="128" /></div>
			</div>
			
			<div class="header" v-else>
				<div class="header_module">
					
					<ul class="surface-card p-2 m-0 list-none flex overflow-x-auto select-none" >
						<li class="pr-3"  @click="scrollToElement2('tabneuf')">
							<a v-ripple class="cursor-pointer px-4 py-3 flex align-items-center hover:surface-100 border-round transition-colors transition-duration-150 p-ripple"  :class="{'bg-primary hover:bg-primary': ((activeSection === 'tabprogrammes' || activeSection === 'tabsecteur' || activeSection === 'tabsynoptique' || activeSection === 'tabsynoptiquepc') && app.livedata.synoptique.length), 'text-700': !(activeSection === 'tabprogrammes' || activeSection === 'tabsecteur' || activeSection === 'tabsynoptique' || activeSection === 'tabsynoptiquepc')}">
								<i class="pi pi-building mr-2"></i>
								<span class="font-medium">{{ $t('neuf') }}</span>
							</a>
						</li>
						<li class="flex align-items-center">
							<div style="width:1px; height: 50%" class="border-right-1 surface-border"></div>
						</li>
						<li class="px-3" @click="scrollToElement2('tabrs')" :class="{inactif: !app.module_rs, active: (activeSection === 'tabsynoptiquers' || !app.livedata.volume_periode_1)}">
							<a v-ripple class="cursor-pointer px-4 py-3 flex align-items-center hover:surface-100 border-round transition-colors transition-duration-150 p-ripple"  :class="{'bg-primary hover:bg-primary': (activeSection === 'tabsynoptiquers' || !app.livedata.volume_periode_1), 'text-700': !(activeSection === 'tabsynoptiquers' || !app.livedata.volume_periode_1)}">
								<i class="pi pi-users mr-2"></i>
								<span class="font-medium">{{$t('Résidence services')}}</span>
							</a>
						</li>
						<li class="flex align-items-center">
							<div style="width:1px; height: 50%" class="border-right-1 surface-border"></div>
						</li>
						<li v-if="app.mrk.lat && app.module_actif && app.ModuleAdresse && app.module_cityscan" class="px-3" @click="scrollToElement2('tabAncien')">
							<a v-ripple class="cursor-pointer px-4 py-3 flex align-items-center hover:surface-100 border-round transition-colors transition-duration-150 p-ripple"  :class="{'bg-primary hover:bg-primary': activeSection === 'tabAncien', 'text-700': activeSection != 'tabAncien'}">
								<i class="pi pi-key mr-2"></i>
								<span class="font-medium">{{ $t('ancien') }}</span>
							</a>
						</li>
						<li v-else class="px-3" @click="clickancien();" @mouseover="showMessage0 = true" @mouseout="showMessage0 = false">
							<a v-ripple class="cursor-pointer px-4 py-3 flex align-items-center surface-100 border-round transition-colors transition-duration-150 p-ripple"  :class="{'bg-primary hover:bg-primary': activeSection === 'tabAncien', 'text-700': activeSection != 'tabAncien'}">
								<i class="pi pi-key mr-2"></i>
								<span class="font-medium">{{ $t('ancien') }}</span>
							</a>
						</li>
						<li class="flex align-items-center" @click="$store.dispatch('set_view', { view: 'locatif' })">
							<div style="width:1px; height: 50%" class="border-right-1 surface-border"></div>
						</li>
						<li class="px-3" v-if="app.module_actif && app.module_cityscan" @click="scrollToElement2('tabLocatif')">
							<a v-ripple class="cursor-pointer px-4 py-3 flex align-items-center hover:surface-100 border-round transition-colors transition-duration-150 p-ripple"  :class="{'bg-primary hover:bg-primary': (activeSection === 'tabLocatif'), 'text-700': !(activeSection === 'tabLocatif')}">
								<i class="pi pi-user mr-2"></i>
								<span class="font-medium">{{ $t('locatif') }}</span>
							</a>
						</li>
						<li class="px-3" v-else @click="clickancien();" @mouseover="showMessage1 = true" @mouseout="showMessage1 = false">
							<a v-ripple class="cursor-pointer px-4 py-3 flex align-items-center surface-100 border-round transition-colors transition-duration-150 p-ripple"  :class="{'bg-primary hover:bg-primary': (activeSection === 'tabLocatif'), 'text-700': !(activeSection === 'tabLocatif')}">
								<i class="pi pi-user mr-2"></i>
								<span class="font-medium">{{ $t('locatif') }}</span>
							</a>
						</li>
						<li class="flex align-items-center" @click="app.mrk.lat ? $store.dispatch('set_view', { view: 'adresse' }) : $store.dispatch('add_notification', { status: 'error', message: messagewarning }) ">
							<div style="width:1px; height: 50%" class="border-right-1 surface-border"></div>
						</li>
						<li class="px-3" v-if ="app.mrk.lat && app.module_actif && app.ModuleAdresse && app.module_cityscan" @click="app.mrk.lat ? scrollToElement2('tabAdresse') : $store.dispatch('add_notification', { status: 'error', message: messagewarning }) ">
							<a v-ripple class="cursor-pointer px-4 py-3 flex align-items-center hover:surface-100 border-round transition-colors transition-duration-150v"  :class="{'bg-primary hover:bg-primary': activeSection === 'tabAdresse', 'text-700': activeSection != 'tabAdresse'}">
								<i class="pi pi-map-marker mr-2"></i>
								<span class="font-medium">{{ $t('qualite_de_ladresse') }}</span>
							</a>
						</li>
						<li class="px-3" v-else @click="clickancien();" @mouseover="showMessage2 = true" @mouseout="showMessage2 = false">
							<a v-ripple class="cursor-pointer px-4 py-3 flex align-items-center surface-100 border-round transition-colors transition-duration-150v"  :class="{'bg-primary hover:bg-primary': activeSection === 'tabAdresse', 'text-700': activeSection != 'tabAdresse'}">
								<i class="pi pi-map-marker mr-2"></i>
								<span class="font-medium">{{ $t('qualite_de_ladresse') }}</span>
							</a>
						</li>
						<li class="flex align-items-center">
							<div style="width:1px; height: 50%" class="border-right-1 surface-border"></div>
						</li>
						<li class="px-3"  @click="scrollToElement2('tabTerritoire')">
							<a v-ripple class="cursor-pointer px-4 py-3 flex align-items-center hover:surface-100 border-round transition-colors transition-duration-150 p-ripple"  :class="{'bg-primary hover:bg-primary': activeSection === 'tabTerritoire', 'text-700': activeSection !== 'tabTerritoire'}">
								<div class="mr-2"><icon file="travel" :size="16"/></div>
								<span class="font-medium">{{ $t('Fiche Territoire') }}</span>
							</a>
						</li>
					</ul>	
					
				</div>
				<div class="toreport">
					<Button icon="pi pi-map" v-if="!app.loading" :label="$t('Retour cartographie')" class="p-button-rounded p-button-success" @click="set_back();$store.dispatch('set_help_live', {aide:false})" />
					<Button icon="pi pi-map" v-else :label="$t('Retour cartographie')" class="p-button-rounded p-button-success" disabled="disabled" />
				</div>
			</div>
			
			<div class="download_div_big" v-if="app.report_dispo">
				<div class="download_div">
					<div class="print-button"><a :href="app.url_report" target="_blank" download="Rapport.pdf"  @click="set_report()"><icon file="download" :size="96" /></a></div>
				</div>
			</div>
			
			<div style="height:60px;width:100%;background: #f8f9fa !important; border-bottom: #ccc solid 1px;" class="header_module">
				<div style="display: flex; width:calc(100% - 240px);" v-if="(activeSection === 'tabprogrammes' || activeSection === 'tabsecteur' || activeSection === 'tabsynoptique' || activeSection === 'tabsynoptiquepc' || activeSection === 'tabsynoptiqueap')">
					<div class="module_theme2" :class="{ active: activeSection === 'tabsecteur' }" v-if="ActiviteNonNulle()">
						<span v-if="ActiviteNonNulle()" class="module_titre" @click="scrollToElement2('tabsecteur')">{{ $t('conjoncture_marche') }}</span>
					</div>
					<div class="module_theme2" :class="{ active: activeSection === 'tabsynoptique' }" v-if="getNbSyno('synoptique')">
						<span v-if="getNbSyno('synoptique')" class="module_titre" @click="scrollToElement2('tabsynoptique')">{{ $t('synoptique_programmes') }}</span>
					</div>
					<div v-if="getNbSyno('synoptique_pc')" class="module_theme2" :class="{ active: activeSection === 'tabsynoptiquepc' }">
						<span v-if="getNbSyno('synoptique_pc')" class="module_titre" @click="scrollToElement2('tabsynoptiquepc')">{{ $t('permis_de_construire') }}</span>
					</div>
					<div v-if="getNbSyno('synoptique_ap')" class="module_theme2" :class="{ active: activeSection === 'tabsynoptiqueap' }">
						<span v-if="getNbSyno('synoptique_ap')" class="module_titre" @click="scrollToElement2('tabsynoptiqueap')">{{ $t('Avant-premières') }}</span>
					</div>
					<div class="module_theme2" v-if="app.nbprogselect>0" :class="{ active: activeSection === 'tabprogrammes' }">
						<span v-if="app.livedata.volume_periode_1" class="module_titre" @click="scrollToElement2('tabprogrammes')">{{ $t('optimisation_de_projet') }}</span>
					</div>
				</div>
				<div v-if="activeSection === 'tabsynoptiquers'" class="module_theme2" :class="{ active: activeSection === 'tabsynoptiquers' }">
					<span class="module_titre">{{ $t('residences_de_services') }}</span>
				</div>
				<div v-if="activeSection === 'tabAncien'" class="module_theme2" :class="{ active: activeSection === 'tabAncien' }">
					<span class="module_titre">{{ $t('ancien') }}</span>
				</div>
				<div v-if="activeSection === 'tabLocatif'" class="module_theme2" :class="{ active: activeSection === 'tabLocatif' }">
					<span class="module_titre">{{ $t('locatif') }}</span>
				</div>
				<div v-if="activeSection === 'tabAdresse'" class="module_theme2" :class="{ active: activeSection === 'tabAdresse' }">
					<span class="module_titre">{{ $t('Qualité d\'adresse') }}</span>
				</div>
				<div v-if="activeSection === 'tabTerritoire'" class="module_theme2" :class="{ active: activeSection === 'tabTerritoire' }">
					<span class="module_titre">{{ $t('Fiche Territoire') }}</span>
				</div>
			</div>
			<div class="header" style="background: #f8f9fa !important;position: absolute; top: 66px; width:340px; height:60px;right: 0px;z-index:500">
				<div class="infinite-loader" v-if="app.loading_pdf" style="display: flex;width: 280px;justify-content: space-around;">
					<div style="line-height: 24px; color: var(--primary-500)">
						{{ $t('Génération du rapport en cours') }}
					</div>
					<div class="loader">
						<icon file="loader" :size="28" style="color:#6c21f9;" />
					</div>
				</div>
				<div class="print-button" v-if="!app.loading&&app.livedata.volume_periode_1" @click="exportPdfVisible = !exportPdfVisible; choix_report = !choix_report;"><icon file="printer" :size="28" /></div> <div class="print-button" v-else style="background-color: #ccc;"><icon file="printer" :size="28" /></div>
			</div>
			<div class="right-bar" v-if="app.view == 'report'" v-show="!app.loading_calc" style="background-color:#fff" id="ReportContent">
				<div class="right-bar-content" v-bar>
					<div class="" id="contentreport" ref="scrollable_content">
					<!--
						<tab-secteur ref="tabsecteur" id="tabsecteur" class="section" v-if="app.livedata.volume_periode_1 && app.nbprogselect" v-observe-visibility="onVisibilityChange_tabsecteur"/>
						<div style="height:5px"></div>
						<tab-synoptique ref="tabsynoptique" id="tabsynoptique" class="section" v-if="app.livedata.volume_periode_1 && app.nbprogselect" v-observe-visibility="onVisibilityChange_tabsynoptique"/>
						<div style="height:5px"></div>
						<tab-synoptique-pc ref="tabsynoptiquepc" id="tabsynoptiquepc" class="section" v-if="app.livedata.volume_periode_1 && app.nbprogselect" v-observe-visibility="onVisibilityChange_tabsynoptiquepc"/>
						<div style="height:5px"></div>
						<tab-synoptique-ap ref="tabsynoptiqueap" id="tabsynoptiqueap" class="section" v-if="app.livedata.volume_periode_1 && app.nbprogselect" v-observe-visibility="onVisibilityChange_tabsynoptiqueap"/>
						<div style="height:5px"></div>
					-->					
						<div ref="tabneuf" id="tabneuf">
							<tab-secteur ref="tabsecteur" id="tabsecteur" class="section" v-if="ActiviteNonNulle()" v-observe-visibility="onVisibilityChange_tabsecteur"/>
							<div style="height:5px"></div>
							<tab-synoptique ref="tabsynoptique" id="tabsynoptique" class="section" v-if="getNbSyno('synoptique')" v-observe-visibility="onVisibilityChange_tabsynoptique"/>
							<div style="height:5px"></div>
							<tab-synoptique-pc ref="tabsynoptiquepc" id="tabsynoptiquepc" class="section" v-if="getNbSyno('synoptique_pc')" v-observe-visibility="onVisibilityChange_tabsynoptiquepc"/>
							<div style="height:5px"></div>
							<tab-synoptique-ap ref="tabsynoptiqueap" id="tabsynoptiqueap" class="section" v-if="getNbSyno('synoptique_ap')" v-observe-visibility="onVisibilityChange_tabsynoptiqueap"/>
							<div style="height:5px"></div>
							<tab-programmes ref="tabprogrammes" id="tabprogrammes" class="section" v-if="app.nbprogselect>0" v-observe-visibility="onVisibilityChange_tabprogrammes"/>
							<div style="height:5px"></div>
						</div>
						<div ref="tabrs" id="tabrs">
							<tab-synoptique-rs ref="tabsynoptiquers" id="tabsynoptiquers" class="section" v-show="app.module_rs && app.livedata.synoptique_rs.length"  v-observe-visibility="onVisibilityChange_tabsynoptiquers"/>						
						</div>
						<div style="height:5px"></div>
						<tab-ancien ref="tabAncien" id="tabAncien" class="section" v-if="app.cityscan.adresse && app.mrk.lat && app.module_actif && app.ModuleAdresse && app.module_cityscan" v-observe-visibility="onVisibilityChange_tabAncien"/>
						<div style="height:5px"></div>
						<tab-locatif ref="tabLocatif" id="tabLocatif" class="section" v-if="app.cityscan.adresse && app.module_actif && app.module_cityscan" v-observe-visibility="onVisibilityChange_tabLocatif"/>
						<div style="height:5px"></div>
						<tab-adresse ref="tabAdresse" id="tabAdresse" class="section" v-if="app.mrk.lat && app.module_actif && app.ModuleAdresse && app.module_cityscan" v-observe-visibility="onVisibilityChange_tabAdresse"/>
						<div style="height:5px">&nbsp;</div>
						<tab-territoire ref="tabTerritoire" id="tabTerritoire" class="section" v-if="app.insee[0]" v-observe-visibility="onVisibilityChange_tabTerritoire"/>
						<div style="height:5px">&nbsp;</div>
					</div>
				</div>
			</div>
			<div class="left-bar" v-if="app.view == 'report'" v-show="!app.loading_calc">
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
import TabSecteur from './pane-report/TabSecteur'
import TabProgrammes from './pane-report/TabProgrammes'
import TabAdresse from './pane-report/TabAdresse'
import TabTerritoire from './pane-report/TabTerritoire'
import TabAncien from './pane-report/TabAncien'
import TabLocatif from './pane-report/TabLocatif'
import TabSynoptique from './pane-report/TabSynoptique'
import TabSynoptiqueAp from './pane-report/TabSynoptiqueAp'
import TabSynoptiquePc from './pane-report/TabSynoptiquePc'
import TabSynoptiqueRs from './pane-report/TabSynoptiqueRs'
import axios from 'axios'
import { ObserveVisibility  } from 'vue-observe-visibility'
import Button from 'primevue/button';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import draggable from "vuedraggable";
//import Textarea from 'primevue/textarea';
// eslint-disable-next-line
import Editor from 'primevue/editor';

export default {
	name: 'PaneReport',
	data: function() {
		return {
			modele: 'standard',
			comment: '',
			etude_titre: '',
			items: [
				{ id: 1, name: "Module de la dynamique territoriale" , actif: true , sousItems: []},
				{ id: 2, name: "Carte de la commercialité du projet" , actif: true , sousItems: []},
				{ id: 3, name: "Module du neuf", actif: true , sousItems: [
					{parent_id: 3, id:1, name: 'Volumes', actif: true}, 
					{parent_id: 3, id:2, name: 'Dispositifs', actif: true}, 
					{parent_id: 3, id:3, name: 'Offre', actif: true}, 
					{parent_id: 3, id:4, name: 'Rythme', actif: true}, 
					{parent_id: 3, id:5, name: 'Prix', actif: true}, 
					{parent_id: 3, id:6, name: 'Optimisation', actif: true}, 
					{parent_id: 3, id:7, name: 'Synoptiques', actif: true}
				]},
				{ id: 4, name: "Module des résidences de services" , actif: true , sousItems: []},
				{ id: 5, name: "Module de l'ancien" , actif: true , sousItems: []},
				{ id: 6, name: "Module du locatif" , actif: true , sousItems: []},
				{ id: 7, name: "Module de la qualité d'adresse" , actif: true , sousItems: true , items: []},
			],
			exportPdfVisible: false,
			choixmodule: false,
			choix_module: {
				disabled: {
					neuf: false,
					rs: true,
					ancien: false,
					locatif: false,
					adresse: false
				},
				neuf: true,
				rs: false,
				ancien: true,
				locatif: true,
				adresse: true
			},
			choix_report: false,
			tableau_optimisation: false,
			activeSection2: null,
			activeSection_tabprogrammes: false,
			activeSection_tabsecteur: false,
			activeSection_tabsynoptique: false,
			activeSection_tabsynoptiquepc: false,
			activeSection_tabsynoptiqueap: false,
			activeSection_tabsynoptiquers: false,
			activeSection_tabAncien: false,
			activeSection_tabLocatif: false,
			activeSection_tabAdresse: false,
			activeSection_tabTerritoire: false,
		}
	},

	computed: Object.assign(mapGetters([ 'app', 'report', 'selected_programs', 'user' ]), {
		nbmodule:function(){
			let n = 0;
			if (this.choix_module.neuf) n = n + 1;
			if (this.choix_module.rs) n = n + 1;
			if (this.choix_module.ancien) n = n + 1;
			if (this.choix_module.locatif) n = n + 1;
			if (this.choix_module.adresse) n = n + 1;
			return n;
		},

		activeSection:function(){
			let s = 'tabprogrammes'
			if (this.activeSection_tabprogrammes) {
				s = 'tabprogrammes'
			} else {
				if (this.activeSection_tabsecteur) {
					s = 'tabsecteur'
				} else {
					if (this.activeSection_tabsynoptique) {
						s = 'tabsynoptique'
					} else {
						if (this.activeSection_tabsynoptiquepc) {
							s = 'tabsynoptiquepc'
						} else {
							if (this.activeSection_tabsynoptiqueap) {
								s = 'tabsynoptiqueap'
							} else {
								if (this.activeSection_tabsynoptiquers) {
									s = 'tabsynoptiquers'
								} else {
									if (this.activeSection_tabAncien) {
										s = 'tabAncien'
									} else {
										if (this.activeSection_tabLocatif) {
											s = 'tabLocatif'
										} else {
											if (this.activeSection_tabAdresse) {
												s = 'tabAdresse'
											} else {
												s = 'tabTerritoire'
											}
										}
									}
								}
							}
						}
					}
				}
			}
			
			return s
		},

		windowHeight() {
			return window.innerHeight;
		}

	}),
	watch: {
		
		'app.view': function(newValue) {
			if (newValue=='report') {
				this.choix_module.disabled.neuf = false
				this.choix_module.disabled.rs = false
				this.choix_module.disabled.ancien = false
				this.choix_module.disabled.locatif = false
				this.choix_module.disabled.adresse = false
				this.choix_module.neuf = true
				if (!this.ActiviteNonNulle() && this.app.livedata.synoptique.length<2 && this.app.livedata.synoptique_pc.length<2 && this.app.livedata.synoptique_ap.length<2) {
					this.choix_module.neuf = false
				}
				if (!this.app.module_rs) {
					this.choix_module.rs = false
					this.choix_module.disabled.rs = true
				}
				if (!(this.app.module_actif && this.app.module_cityscan)) {
					this.choix_module.locatif = false
					this.choix_module.disabled.locatif = true
				}
				if (!(this.app.mrk.lat && this.app.module_actif && this.app.ModuleAdresse && this.app.module_cityscan)) {
					this.choix_module.ancien = false
					this.choix_module.adresse = false
					this.choix_module.disabled.ancien = true
					this.choix_module.disabled.adresse = true
				}
			}
		},
	},

	directives: {
		'observe-visibility': ObserveVisibility
	},

	methods: {	
		getNbSyno: function(syno) {
			let n = 0;
			for (let index = 0; index < this.app.livedata[syno].length; index++) {
				const element = this.app.livedata[syno][index];
				n = n + element.tableau.length;
			}
			return n
		},
		inverse: function(i, ii, b) {
			for (let index = 0; index < this.items.length; index++) {
				if (this.items[index].id == i) {
					for (let index2 = 0; index2 < this.items[index].sousItems.length; index2++) {
						if (this.items[index].sousItems[index2].id == ii) {
							if (b==null) this.items[index].sousItems[index2].actif = !this.items[index].sousItems[index2].actif;
							else this.items[index].sousItems[index2].actif = b;
							break;
						}
					}					
				}
			}
		},
		onDragEnd() {
			console.log("Ordre des éléments modifié :", this.items);
		},
		ActiviteNonNulle: function() {
			let v = 0
			for (let index = 0; index < this.app.livedata.volume_periode_2.nbprog.tableau.length; index++) {
				v = v + this.app.livedata.volume_periode_2.nbprog.tableau[index];
			}
			return v;
		},
		onChangeModules: function(v) {
			if (this.nbmodule == 0) this.choix_module[v] = true
		},

		onVisibilityChange_tabAdresse(isVisible) {
			this.activeSection_tabAdresse = isVisible;
		},

		onVisibilityChange_tabTerritoire(isVisible) {
			this.activeSection_tabTerritoire = isVisible;
		},

		onVisibilityChange_tabprogrammes(isVisible) {
			this.activeSection_tabprogrammes = isVisible;
		},

		onVisibilityChange_tabsecteur(isVisible) {
			this.activeSection_tabsecteur = isVisible;
		},

		onVisibilityChange_tabsynoptique(isVisible) {
			this.activeSection_tabsynoptique = isVisible;
		},

		onVisibilityChange_tabsynoptiquepc(isVisible) {
			this.activeSection_tabsynoptiquepc = isVisible;
		},

		onVisibilityChange_tabsynoptiqueap(isVisible) {
			this.activeSection_tabsynoptiqueap = isVisible;
		},

		onVisibilityChange_tabAncien(isVisible) {
			this.activeSection_tabAncien = isVisible;
		},

		onVisibilityChange_tabLocatif(isVisible) {
			this.activeSection_tabLocatif = isVisible;
		},

		onVisibilityChange_tabsynoptiquers(isVisible) {
			this.activeSection_tabsynoptiquers = isVisible;
		},

		scrollToElement(elementId) {
			let that = this
			let $scroll = $(that.$refs.scrollable_content)
			let $program = $scroll.find('#' + elementId)
			let x = $program[0].offsetTop;
			let element = $program[0].offsetParent;
			while (element) {
				x += element.offsetTop
				try {
					x -= 30
					element = element.offsetParent;
				} catch (error) {
					break;
				}
			}
			$scroll.animate({ scrollTop: x }, 250)
		},

		scrollToElement2(elementId) {
			const element2 = document.getElementById(elementId);
			element2.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
		},

		handleScroll() {
			const observer = new IntersectionObserver(entries => {
				entries.forEach(entry => {
					if (entry.isIntersecting) {
						let contentreport = document.getElementById("contentreport").scrollTop
						if (contentreport<=(document.getElementById("tabprogrammes").clientHeight-100)) {
							this.activeSection = 'tabprogrammes'
						} else {
							if (contentreport<=document.getElementById("tabprogrammes").clientHeight + (document.getElementById("tabsecteur").clientHeight-100)) {
								this.activeSection = 'tabsecteur'
								if (contentreport<=document.getElementById("tabprogrammes").clientHeight + (document.getElementById("tabsecteurnom").clientHeight + (document.getElementById("tabsecteuretat").clientHeight-100))) {
									this.activeSection2 = 'tabsecteuretat'
								} else {
									if (contentreport<=document.getElementById("tabprogrammes").clientHeight + document.getElementById("tabsecteurnom").clientHeight + document.getElementById("tabsecteuretat").clientHeight + document.getElementById("tabsecteurinv").clientHeight-100) {
										this.activeSection2 = 'tabsecteurinv'
									} else {
										if (contentreport<=document.getElementById("tabprogrammes").clientHeight + document.getElementById("tabsecteurnom").clientHeight + document.getElementById("tabsecteuretat").clientHeight + document.getElementById("tabsecteurinv").clientHeight + document.getElementById("tabsecteurqpv").clientHeight-100) {
											this.activeSection2 = 'tabsecteurqpv'
										} else {
											if (contentreport<=document.getElementById("tabprogrammes").clientHeight + document.getElementById("tabsecteurnom").clientHeight + document.getElementById("tabsecteuretat").clientHeight + document.getElementById("tabsecteurinv").clientHeight + document.getElementById("tabsecteurqpv").clientHeight + document.getElementById("tabsecteuroffre").clientHeight-100) {
												this.activeSection2 = 'tabsecteuroffre'
											} else {
												this.activeSection2 = 'tabsecteurprix'
											}
										}
									}									
								}
							} else {
								if (contentreport<=document.getElementById("tabprogrammes").clientHeight + document.getElementById("tabsecteur").clientHeight + (document.getElementById("tabsynoptique").clientHeight-100)) {
									if (contentreport+(document.getElementById("contentreport").clientHeight/2)<=document.getElementById("tabprogrammes").clientHeight + document.getElementById("tabsecteur").clientHeight + document.getElementById("tabsynoptique").clientHeight ) {
										this.activeSection = 'tabsynoptique'
									} else this.activeSection = 'tabsynoptiquepc'
								} else {
									if (contentreport<=document.getElementById("tabprogrammes").clientHeight + document.getElementById("tabsecteur").clientHeight + document.getElementById("tabsynoptique").clientHeight + (document.getElementById("tabsynoptiquepc").clientHeight-100)) {
										this.activeSection = 'tabsynoptiquepc'
									} else {
										this.activeSection = 'tabsynoptiquers'
									}
								}
							}
						}
					}
				});

			});
			const elements = document.querySelectorAll('.section'); 
			elements.forEach(element => {
				observer.observe(element);
			});
		},

		export_html_report: function(){
			let zone = document.getElementById('report-programmes').innerHTML;
			zone = zone + document.getElementById('report-secteurs').innerHTML;
			zone = zone + document.getElementById('report-synoptique').innerHTML;
			zone = zone + document.getElementById('report-pc').innerHTML;

			let fen = window.open("", "", "height=400, width=2024,toolbar=0, menubar=0, scrollbars=1, resizable=1,status=0, location=0, left=10, top=10")
			
			// style du popup
			fen.document.body.style.color = '#000000'
			fen.document.body.style.backgroundColor = '#FFFFFF'
			fen.document.body.style.padding = "0px"
			
			// Ajout des données a imprimer
			fen.document.title = 'Report'


			axios.get('https://www.myadequation.fr/EFOCUS/print_report.txt?' + _.now(), {})
			.then(response => {
			fen.document.body.innerHTML = '<style>' + response.data + '</style>'
			fen.document.body.innerHTML += " " + zone + " "
			
			// Impression du popup
			fen.window.print()
			
			//Fermeture du popup
			fen.window.close()
			return true
			}).catch(console.error)
		},

		export_html: function(){
			let zone = document.getElementById(data.divname).innerHTML;
			
			// Ouverture du popup
			let fen = window.open("", "", "height=1050, width=700,toolbar=0, menubar=0, scrollbars=1, resizable=1,status=0, location=0, left=10, top=10")
			
			// style du popup
			fen.document.body.style.color = '#000000'
			fen.document.body.style.backgroundColor = '#FFFFFF'
			fen.document.body.style.padding = "0px"
			
			// Ajout des données a imprimer
			fen.document.title = data.titre


			axios.get('https://www.myadequation.fr/EFOCUS/print_program.txt?' + _.now(), {})
			.then(response => {
			fen.document.body.innerHTML = '<style>' + response.data + '</style>'
			zone = zone.replace('height: 285px', '')
			fen.document.body.innerHTML += " " + zone + " "
			
			// Impression du popup
			fen.window.print()
			
			//Fermeture du popup
			fen.window.close()
			return true
			}).catch(console.error)
		},

		set_report: function() {
			this.$store.dispatch('set_report_dispo', { dispo: false })
		},

		click_tab: function(tab) {
			this.$store.dispatch('set_tab', { tab: tab })
			this.$router.push({ name: tab}).catch(()=>{});
		},

		toggle_report: function() {
			let view = 'report'
			if (this.app.view == 'report') view = 'list'
			this.set_view(view)
		},

		set_back: function() {
			this.$store.dispatch('set_view', { view: this.app.source_view })
			for(let i = 0; i < this.app.livedata.synoptique.length; i++){
				for(let j = 0; j < this.app.livedata.synoptique[i].tableau.length; j++){
					if (document.getElementById(this.app.livedata.synoptique[i].tableau[j].program_id + "-" + this.app.livedata.synoptique[i].tableau[j].ville)) {
						document.getElementById(this.app.livedata.synoptique[i].tableau[j].program_id + "-" + this.app.livedata.synoptique[i].tableau[j].ville).className = 'checkbox checked'
					}
				}
			}
			for(let i = 0; i < this.app.livedata.synoptique_pc.length; i++){
				for(let j = 0; j < this.app.livedata.synoptique_pc[i].tableau.length; j++){
					if (document.getElementById(this.app.livedata.synoptique_pc[i].tableau[j].program_id + "-" + this.app.livedata.synoptique_pc[i].tableau[j].ville)) {
						document.getElementById(this.app.livedata.synoptique_pc[i].tableau[j].program_id + "-" + this.app.livedata.synoptique_pc[i].tableau[j].ville).className = 'checkbox checked'
					}
				}
			}
			for(let i = 0; i < this.app.livedata.synoptique_ap.length; i++){
				for(let j = 0; j < this.app.livedata.synoptique_ap[i].tableau.length; j++){
					if (document.getElementById(this.app.livedata.synoptique_ap[i].tableau[j].program_id + "-" + this.app.livedata.synoptique_ap[i].tableau[j].ville)) {
						document.getElementById(this.app.livedata.synoptique_ap[i].tableau[j].program_id + "-" + this.app.livedata.synoptique_ap[i].tableau[j].ville).className = 'checkbox checked'
					}
				}
			}
		},
		
		set_view: function(view) {
			this.$store.dispatch('set_view', { view: view })
		},

		set_html: function() {

		},

		get_pdf_perso: function() {
			this.get_pdf(true, this.modele)
		},

		get_pdf: function(modele, nommodele) {
			if (this.app.tab != 'mois') {
				this.$store.dispatch('change_periodicite', { periodicite: 'mois' })
			}
			this.choix_report = false
			this.tableau_optimisation = []
			let elem = document.createElement('textarea')
			let price_min = ""
			let price_moy = ""
			let price_max = ""
			for (let i=1; i<13;i++) {
				if (document.getElementById('opt_lib_' + i) && document.getElementById('opt_si_' + i)) {
					elem.innerHTML = document.getElementById('opt_pummin_' + i).innerHTML
					price_min = elem.value
					elem.innerHTML = document.getElementById('opt_pummoy_' + i).innerHTML
					price_moy = elem.value
					elem.innerHTML = document.getElementById('opt_pummax_' + i).innerHTML
					price_max = elem.value
					this.tableau_optimisation.push(
						{
							libelle : document.getElementById('opt_lib_' + i).innerHTML,
							stock_initial: document.getElementById('opt_si_' + i).innerHTML,
							offre_total: document.getElementById('opt_offre_' + i).innerHTML,
							ventes_totales: document.getElementById('opt_ventes_' + i).innerHTML,
							rythme: document.getElementById('opt_rythme_' + i).innerHTML,
							surface_min: document.getElementById('opt_surfmin_' + i).innerHTML,
							surface_moy: document.getElementById('opt_surfmoy_' + i).innerHTML,
							surface_max: document.getElementById('opt_surfmax_' + i).innerHTML,
							pum_min: price_min,
							pum_moy: price_moy,
							pum_max: price_max,
							stock_initial_nb: document.getElementById('opt_si_nb_' + i).innerHTML,
							offre_total_nb: document.getElementById('opt_offre_nb_' + i).innerHTML,
							ventes_totales_nb: document.getElementById('opt_ventes_nb_' + i).innerHTML,
							rythme_mois: document.getElementById('opt_rythme_mois_' + i).innerHTML,
							rythme_vtemois: document.getElementById('opt_rythme_vtemois_' + i).innerHTML,
						}
					)
				}
			}
			let modules = ''
			if (this.choix_module.neuf) modules = modules + '1,'
			if (this.choix_module.rs) modules = modules + '2,'
			if (this.choix_module.ancien) modules = modules + '3,'
			if (this.choix_module.locatif) modules = modules + '4,'
			if (this.choix_module.adresse) modules = modules + '5,'

			modules = ''
			for (let index = 0; index < this.items.length; index++) {
				const element = this.items[index];
				if (element.id == 3 && element.actif) {
					modules = modules + '1,'
					for (let index2 = 0; index2 < element.sousItems.length; index2++) {
						if (element.sousItems[index2].actif) modules = modules + '1.' + element.sousItems[index2].id + ','
					}
				}
				if (element.id == 4 && element.actif) modules = modules + '2,'
				if (element.id == 5 && element.actif) modules = modules + '3,'
				if (element.id == 6 && element.actif) modules = modules + '4,'
				if (element.id == 7 && element.actif) modules = modules + '5,'
				if (element.id == 1 && element.actif) modules = modules + '6,'
				if (element.id == 2 && element.actif) modules = modules + '7,'
			}

			if (modele) {
				this.$store.dispatch('get_report_pdf_dynamic', { modele: modele, nommodele: nommodele, etude_titre: this.etude_titre, tableau: this.tableau_optimisation, entree: this.app.optimisation.entree, destination: this.app.optimisation.destination, tva: this.app.optimisation.financement, prix: this.app.optimisation.prix, modules: modules })
			} else {
				this.$store.dispatch('get_report_pdf', { modele: modele, nommodele: nommodele, etude_titre: this.etude_titre, tableau: this.tableau_optimisation, entree: this.app.optimisation.entree, destination: this.app.optimisation.destination, tva: this.app.optimisation.financement, prix: this.app.optimisation.prix, modules: modules })
			}
		},

		get_pdf3: function() {
			this.tableau_optimisation = []
			let elem = document.createElement('textarea')
			let price_min = ""
			let price_moy = ""
			let price_max = ""
			for (let i=1; i<13;i++) {
				if (document.getElementById('opt_lib_' + i)) {
					elem.innerHTML = document.getElementById('opt_pummin_' + i).innerHTML
					price_min = elem.value
					elem.innerHTML = document.getElementById('opt_pummoy_' + i).innerHTML
					price_moy = elem.value
					elem.innerHTML = document.getElementById('opt_pummax_' + i).innerHTML
					price_max = elem.value
					this.tableau_optimisation.push(
						{
							libelle : document.getElementById('opt_lib_' + i).innerHTML,
							stock_initial: document.getElementById('opt_si_' + i).innerHTML,
							offre_total: document.getElementById('opt_offre_' + i).innerHTML,
							ventes_totales: document.getElementById('opt_ventes_' + i).innerHTML,
							rythme: document.getElementById('opt_rythme_' + i).innerHTML,
							surface_min: document.getElementById('opt_surfmin_' + i).innerHTML,
							surface_moy: document.getElementById('opt_surfmoy_' + i).innerHTML,
							surface_max: document.getElementById('opt_surfmax_' + i).innerHTML,
							pum_min: price_min,
							pum_moy: price_moy,
							pum_max: price_max,
							stock_initial_nb: document.getElementById('opt_si_nb_' + i).innerHTML,
							offre_total_nb: document.getElementById('opt_offre_nb_' + i).innerHTML,
							ventes_totales_nb: document.getElementById('opt_ventes_nb_' + i).innerHTML,
							rythme_mois: document.getElementById('opt_rythme_mois_' + i).innerHTML,
							rythme_vtemois: document.getElementById('opt_rythme_vtemois_' + i).innerHTML,
						}
					)
				}
			}
			this.$store.dispatch('get_report_pdf3', { tableau: this.tableau_optimisation })
		},

		get_pdf2: function() {
			let zone = document.getElementById('report-programmes').innerHTML;
			zone = zone + document.getElementById('report-secteurs').innerHTML;
			zone = zone + document.getElementById('report-synoptique').innerHTML;
			zone = zone + document.getElementById('report-pc').innerHTML;
			let that = this
			axios.get('https://www.myadequation.fr/EFOCUS/print_report.txt?' + _.now(), {})
			.then(response => {
				zone = '<html><head><style>' + response.data + '</style></head><body>' + zone + '</body></html>'
				that.$store.dispatch('get_report_pdf2', { html: zone })
			}).catch(console.error)

		},
		
	},

	mounted() {		
	},
	
// 	eslint-disable-next-line
 	components: { Editor, InputText, draggable, /*Textarea, */ TabSecteur, TabAdresse, TabTerritoire, TabAncien, TabLocatif, TabProgrammes, TabSynoptique, TabSynoptiquePc, TabSynoptiqueAp, TabSynoptiqueRs, Button, InputSwitch }
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/app'


.pane-report
	position fixed
	left 100%
	top 60px
	bottom 0
	height calc(100% - 59px)
	width 100%
	background-color #333
	color #ccc
	transition 0.5s easeOutQuart
	z-index 500
	&.vi
		width calc(100% - 300px)
		.module_ret
			visibility visible
		.back-button
			visibility visible
		.right-bar
			visibility visible
	&.cart
		transform translateX(-300px)
	&.report
		transform translateX(-100%)
	&.slide-enter, &.slide-leave-to
		transform translateX(0)
	.infinite-loader
		position absolute
		right 80px
		top 18px
		.loader
			display inline-block
			background main_color
			border-radius 100%
			color #fff
			.icon
				display block
				animation rotate 1.5s infinite linear
	.header
		background white
		border-bottom 1px solid #ccc
		height 66px
		color #000
		.back-button
			text-align center
			height 100%
			width 60px
			border-left 1px solid #ccc
			line-height 60px
			position absolute
			left 0px
			top 0px
			cursor pointer
			transition 0.25s easeOutQuart
			&:hover
				background-color primaryA100
				color white
		.print-button
			text-align center
			position absolute
			right 0px
			top 0px
			height 100%
			width 60px
			border-left 1px solid #ccc
			line-height 60px
			cursor pointer
			transition 0.25s easeOutQuart
			&:hover
				background-color primaryA100
				color white
		.print-button-disabled
			text-align center
			position absolute
			right 0px
			top 0px
			height 100%
			width 60px
			border-left 1px solid #ccc
			line-height 60px
			transition 0.25s easeOutQuart
			background-color #ccc;
		.print-button2
			text-align center
			height 100%
			width 60px
			border-left 1px solid #ccc
			line-height 60px
			position absolute
			right 120px
			top 0px
			cursor pointer
			transition 0.25s easeOutQuart
			&:hover
				background-color primaryA100
				color white
		.print-button3
			text-align center
			position absolute
			right 180px
			top 0px
			height 100%
			width 60px
			border-left 1px solid #ccc
			line-height 60px
			cursor pointer
			transition 0.25s easeOutQuart
			&:hover
				background-color primaryA100
				color white
		.help-button
			text-align center
			height 100%
			width 60px
			border-left 1px solid #ccc
			line-height 60px
			position absolute
			right 60px
			top 0px
			cursor pointer
			transition 0.25s easeOutQuart
			&:hover
				background-color primaryA100
				color white
	.tabs2
		color secondary
		background white
		border-left 1px solid hsl(0deg 0% 86%)
		&:after
			content ''
			display block
			clear both
		.tab
			float left
			margin 0 0px 0 0
			height 60px
			width 220px
			display flex
			align-items center
			justify-content center
			flex-direction column
			opacity 1
			font-size 12px
			font-weight 400
			border-bottom 4px solid transparent
			text-align center
			text-transform uppercase
			cursor pointer
			color #a1a5b8
			border-right 1px solid hsl(0deg 0% 86%)
			&:hover
				color #666
			&.active
				opacity 2
				border-bottom-color var(--primary)
				color var(--primary)
			.icon
				display block
				margin 0 0 2px 0
	.left-bar
		height calc(100% - 96px)
		background light
		width 0px
		color #333
		.left-bar-content
			height 100%
			.content
				height 100%
				
	.right-bar
		float right
		height calc(100% - 96px)
		background light
		width 100%
		color #333
		.right-bar-content
			height 100%
			.content
				height 100%

		.scroll-dots
			position absolute
			left -40px
			top 24px
			width 32px
			background alpha(green, 50%)
			border-radius radius
			z-index 2
			transition 0.25s easeOutQuart
			&:hover
				background alpha(green, 75%)
			.dot
				display block
				width 8px
				height 8px
				margin 12px
				background #fff
				opacity 0.25
				border-radius 100%
				transition 0.25s easeOutQuart
				cursor pointer
				&:hover, &.active
					opacity 1
.download_div_big					
	position absolute
	height 100%
	width 100%
	z-index 998
	left 0
	top 0
	text-align center
	background #666
	opacity 0.5
.download_div
	position absolute
	height 96px
	width 96px
	z-index 999
	left 50%
	top 50%
	text-align center
	background #FAFAFA
	border 1px solid rgba(0, 0, 0, 0.6)
	box-sizing border-box
	border-radius 20px
.tab-content
	padding 24px 32px
	.part
		margin 0 0 40px 0
		.part-title
			margin 0 0 8px 0
			line-height 32px
			font-weight 600
			text-transform uppercase
			font-size 16px

#contentreport
	min-height calc(100% + 10px)

.toreport
	position absolute
	right 10px
	top 9px
	height 60px

.buttonv
	border 0
	border-radius 0
	background primaryA400
	border-radius 18px
	font-style normal
	font-size 12px
	text-align center
	letter-spacing 0.75px
	text-transform uppercase
	color #FFFFFF
	width 215px
	height 36px
	cursor pointer
	&:hover
		background secondary
	> a
		color #FFFFFF !important
.top-bar
	height 60px
	background white
	width 100%
	color #333
	.top-bar-content
		width 100%
.contentheader
	width 100%
	display flex
	

.moduletop
	display block
	width 20%
	max-width 220px
	height 59px
	line-height 60px
	padding-left 10px
	vertical-align middle
	background-color #ffffff
	border-right 1px solid #cccccc
	cursor pointer
	text-align left
	&:hover
		background #f9f9f9
		border-bottom 4px solid hsl(153deg 48% 49%)
		background rgba(28, 142, 186, 0.1);


.highcharts-credits
	visibility hidden !important
	display none !important

.dropdown
	color #333
	width 320px
	padding 10px !important
	position absolute
	z-index 999
	top 130px
	right 5px
	background-color #fff
	border-radius 4px
	box-shadow none
	padding-bottom 0.5rem
	border 1px solid #ccc
	padding-top 0
	overflow hidden
	table
		tr
			td
				padding 5px


.company-dashboard .company-header {
	display: flex;
	padding: 20px;
	background: var(--white);
	border: 1px solid var(--fade-grey-dark-3);
	border-radius: 4px;
	margin-bottom: 0.2rem;
}
.company-dashboard .company-header .header-item {
	width: 50%;
	border-right: 1px solid var(--fade-grey-dark-3);
}
.company-dashboard .company-header .header-item:last-child {
	border-right: none;
}
.company-dashboard .company-header .header-item .item-inner {
	text-align: center;
}
.company-dashboard .company-header .header-item .item-inner .lnil, .company-dashboard .company-header .header-item .item-inner .lnir {
	font-size: 1.8rem;
	margin-bottom: 6px;
	color: var(--primary);
}

.lnil {
	display: inline-block;
	font: normal normal normal 1em/1 'LineIconsPro Light';
	speak: none;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.company-dashboard .company-header .header-item .item-inner span {
	display: block;
	font-family: var(--font), sans-serif;
	font-weight: 600;
	font-size: 1.6rem;
	color: var(--dark-text);
}
.company-dashboard .company-header .header-item .item-inner p {
	font-family: var(--font-alt), sans-serif;
	font-size: 0.85rem;
}


.r {
	font-family: var(--font-alt), sans-serif !important;
	font-size: 0.95rem !important;
	font-family: var(--font), sans-serif !important;
	font-weight: 300 !important;
	color: var(--light-text) !important;
	display: initial !important;
}


.company-dashboard .dashboard-card.is-base-chart {
	padding: 0;
	display: flex;
	flex-direction: column;
}

.company-dashboard .dashboard-card {
	flex: 1;
	display: inline-block;
	width: 100%;
	padding: 20px;
	background-color: var(--white);
	border-radius: 6px;
	border: 1px solid var(--fade-grey-dark-3);
	transition: all 0.3s;
	height: 100%;
}
.company-dashboard .dashboard-card.is-base-chart .content-box {
	padding: 30px;
}
.company-dashboard .dashboard-card.is-base-chart .chart-container {
	margin-top: auto;
}
.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats {
	display: flex;
	padding-bottom: 20px;
	border-bottom: 1px solid var(--fade-grey-dark-3);
}
.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats .revenue-stat {
	margin-right: 30px;
	font-family: var(--font), sans-serif;
}
.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats .revenue-stat span:first-child {
	text-transform: uppercase;
	font-family: var(--font-alt), sans-serif;
	font-size: 0.75rem;
	color: var(--light-text);
}

.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats .revenue-stat span {
	display: block;
}
.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats .revenue-stat span.current {
	color: var(--primary);
}

.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats .revenue-stat span:nth-child(2) {
	color: var(--dark-text);
	font-size: 1.6rem;
	font-weight: 600;
}

.progress-bar {
	width: 100%;
	position: absolute;
	height: 4px;
	top: 0px;
	background-color: #f3f3f3;
	border-radius: 5px;
	overflow: hidden;
	z-index: 999;
}

.progress-bar-fill {
	height: 4px;
	background-color: #22c55e;
	transition: width 0.3s ease-in-out;
}


.list {
	display: flex;
	flex-direction: column;
	gap: 10px;
}
.list-item {
	padding: 10px;
	background-color: #f0f0f0;
	border: 1px solid #ddd;
	border-radius: 6px;
}
.drag-handle {
	cursor: grab;
	margin-right: 10px;
	color: #555;
	font-size: 18px;
}
.drag-handle:active {
	cursor: grabbing;
}
/* Ligne fantôme qui suit la souris */
.drag-ghost {
	background-color: #e0e0e0;
	border: 1px dashed #ccc;
	opacity: 0.8;
}

.input-switch {
	display: flex;
	align-items: center;
	gap: 10px;
}

/* Chips styles */
.chips {
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
	margin-left: 20px;
	margin-top: 5px;
}
.chip {
	line-height:24px;
	padding-top:2px;
	padding-bottom:2px;
	padding-left:8px;
	padding-right:8px;
	border-radius: 12px;
	cursor: pointer;
	background-color: #cccccc;
}
.chip_active {
	background-color: #6b23f1;
	color: white;
}
/* Close button */
.close-btn {
  font-size: 14px;
  margin-left: 10px;
  cursor: pointer;
  color: white;
  opacity: 0.8;
  transition: opacity 0.2s ease-in-out;
}
.close-btn:hover {
  opacity: 1;
}

/* Animation quand la div entre */
.slide-right-enter-active {
  transition: transform 0.5s ease, opacity 0.5s ease;
}
.slide-right-enter {
  transform: translateX(100%);
  opacity: 0;
}

/* Animation quand la div sort */
.slide-right-leave-active {
  transition: transform 0.5s ease, opacity 0.5s ease;
}
.slide-right-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

.inactive {
	cursor: not-allowed;
	opacity: 0.5;
}

.close
	color #fff
	cursor pointer
	border-radius 100%
	transition 0.25s easeOutQuart
	background transparent
	color #6c757d
	&:hover
		background #e9ecef

.middle_center
	left 50%
	display inline-block
	transform translate(-50%)

@keyframes rotate
	from
		transform rotate(0deg)
	to
		transform rotate(359deg)

</style>
