import { render, staticRenderFns } from "./PaneAncienMutations.vue?vue&type=template&id=2ca1e0e5&scoped=true"
import script from "./PaneAncienMutations.vue?vue&type=script&lang=js"
export * from "./PaneAncienMutations.vue?vue&type=script&lang=js"
import style0 from "./PaneAncienMutations.vue?vue&type=style&index=0&id=2ca1e0e5&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ca1e0e5",
  null
  
)

export default component.exports