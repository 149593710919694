<template>
	<div>
		<div ref="streetView" class="street-view-container"></div>
	</div>
</template>

<script>
export default {
props: {
	address: {
		type: String,
		required: false,
	},
	coordinates: {
		type: Object,
		required: false,
		default: null, // Exemple : { lat: 37.7749, lng: -122.4194 }
	},
},
data() {
	return {
		panorama: null,
	};
},
mounted() {
	this.loadGoogleMapsApi().then(() => {
		this.initializeStreetView();
	});
},
watch: {
	// Surveiller les changements dans les coordonnées
	'coordinates': function(newValue, oldValue) {
		if (newValue && (newValue != oldValue)) {
			this.showStreetView(newValue);
		}
	},
	'address': function(newValue, oldValue) {
		if (newValue && (newValue != oldValue)) {
			this.geocodeAndShowStreetView(newValue);
		}
	},
	/*
	coordinates: {
		handler(newCoordinates) {
			if (newCoordinates) {
				this.showStreetView(newCoordinates);
			}
		},
		deep: true,
	},
	
	address: {
		handler(newAddress) {
			if (newAddress) {
				this.geocodeAndShowStreetView(newAddress);
			}
		},
	},
	*/
},
  methods: {
	loadGoogleMapsApi() {
	return new Promise((resolve, reject) => {
		if (window.google && window.google.maps) {
		resolve(); // API déjà chargée
		return;
		}
		const script = document.createElement("script");
		script.src = `https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&libraries=places`;
		script.async = true;
		script.defer = true;
		script.onload = resolve;
		script.onerror = reject;
		document.head.appendChild(script);
	});
	},
	initializeStreetView() {
	if (this.coordinates) {
		this.showStreetView(this.coordinates);
	} else if (this.address) {
		const geocoder = new google.maps.Geocoder();
		geocoder.geocode({ address: this.address }, (results, status) => {
		if (status === "OK" && results[0]) {
			const location = results[0].geometry.location;
			this.showStreetView({
			lat: location.lat(),
			lng: location.lng(),
			});
		} else {
			console.error("Adresse introuvable pour Street View :", status);
		}
		});
	}
	},
	showStreetView(coords) {
	this.panorama = new google.maps.StreetViewPanorama(this.$refs.streetView, {
		position: coords,
		pov: {
		heading: 0,
		pitch: 0,
		},
		zoom: 1,
	});
	},
},
};
</script>

<style>
.street-view-container {
width: 740px;
height: 300px; /* Définir une taille pour la vue Street View */
border: 1px solid #ccc;
}
</style>