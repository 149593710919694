import { render, staticRenderFns } from "./Program.vue?vue&type=template&id=5b4cc946&scoped=true"
import script from "./Program.vue?vue&type=script&lang=js"
export * from "./Program.vue?vue&type=script&lang=js"
import style0 from "./Program.vue?vue&type=style&index=0&id=5b4cc946&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b4cc946",
  null
  
)

export default component.exports