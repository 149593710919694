 <template>
	<div class="stat-box" v-if="app.livedata.synoptique_pc.length">
		<div class="stat-box-header">
			<div class="flex align-items-center surface-card">
				<div style="width:48px;height:48px;border-radius:10px" class="bg-green-100 inline-flex align-items-center justify-content-center mr-3">
					<i class="pi pi-list text-green-600 text-3xl"></i>
				</div>
				<div>
					<span class="text-900 text-xl font-medium mb-2">{{ $t('synoptique') }}</span>
					<p class="mt-1 mb-0 text-600 font-medium text-sm">{{ $t('de_votre_selection_de_permis_de_construire') }}</p>
				</div>
				<div class="ml-auto">
					<div class="speeddial-tooltip-demo">
						<SpeedDial className="speeddial-left" :model="items" direction="down" showIcon="pi pi-bars" hideIcon="pi pi-times" buttonClassName="p-button-outlined"/>
					</div>
				</div>
			</div>
		</div>
		<div class="stat-box-content">
			<DataTable showGridlines stripedRows rowGroupMode="subheader" groupRowsBy="secteur" dataKey="id" sortMode="single" sortField="secteur" responsiveLayout="scroll" class="synoptique" id="synopc"
			:value="synojson"
			:selection.sync="selectedPrograms"
			:sortOrder="1"
			:expandableRowGroups="true"
			:expandedRowGroups.sync="expandedRowGroups"
			>
				<ColumnGroup type="header">
					<Row>
						<Column header="" :rowspan="2" :headerStyle="{width: '30px'}">
							<template #header>
								<div class="table-header">
									<Button icon="pi pi-trash" class="p-button-danger p-button-sm" @click="supprime_selection()" :disabled="!selectedPrograms || !selectedPrograms.length" />
								</div>
							</template>
						</Column>
						<Column field="nom" :header="$t('projet')" :rowspan="2" :sortable="true"/>
						<Column field="nature_depositaire" :header="$t('depositaire')" :rowspan="2" :sortable="true"/>
						<Column field="nopc" :header="$t('n°_du_permis')" :rowspan="2" :sortable="true"/>
						<Column field="npc_libelle" :header="$t('type')" :rowspan="2" :sortable="true"/>
						<Column field="tpl_libelle" :header="$t('nature')" :rowspan="2" :sortable="true"/>
						<Column field="statut" :header="$t('statut')" :rowspan="2" :sortable="true"/>
						<Column field="date_accord" :header="$t('autorisation')" :rowspan="2" :sortable="true"/>
						<Column field="forme" :header="$t('f.u.')" :rowspan="2" :sortable="true"/>
						<Column :header="$t('nombre_de_logement')" :colspan="2"/>
						<Column field="surface_habitation" :header="$t('surface')" :sortable="true" :rowspan="2"/>
						<Column field="date_maj" :header="$t('date_de_maj')" :rowspan="2" :sortable="true"/>
						<Column header="" :rowspan="2" ></Column>
					</Row>
					<Row>
						<Column field="nb_log" :header="$t('du_projet')" :sortable="true"/>
						<Column field="nb_log_pc" :header="$t('du_pc')" :sortable="true"/>
					</Row>
				</ColumnGroup>
				<Column selectionMode="multiple" :styles="{'min-width': '3rem', 'text-align': 'center' }"></Column>
				<Column field="nom" :header="$t('projet')">
					<template #body="{data}">
						<p><b>{{ data.nom }}</b></p><p>{{ data.adresse }} &nbsp; {{ data.ville }}</p>
					</template>
				</Column>
				<Column field="nature_depositaire" :header="$t('depositaire')"></Column>
				<Column field="nopc" :header="$t('n°_du_permis')"></Column>
				<Column field="npc_libelle" :header="$t('type')"></Column>
				<Column field="tpl_libelle" :header="$t('nature')"></Column>
				<Column field="statut" :header="$t('statut')"></Column>
				<Column field="date_accord" :header="$t('autorisation')"></Column>
				<Column field="forme" :header="$t('f.u.')"></Column>
				<Column field="nb_log" :header="$t('du_projet')">
					<template #body="{data}">
						<div style="text-align: right;">{{ data.nb_log }}</div>
					</template>
				</Column>
				<Column field="nb_log_pc" :header="$t('du_pc')">
					<template #body="{data}">
						<div style="text-align: right;">{{ data.nb_log_pc }}</div>
					</template>
				</Column>
				<Column field="surface_habitation" :header="$t('surface')">
					<template #body="{data}">
						<div style="text-align: right;">{{ data.surface_habitation }} m²</div>
					</template>
				</Column>
				<Column field="date_maj" :header="$t('date_de_maj')">
					<template #body="{data}">
						<div style="text-align: center;">{{ data.date_maj }}</div>
					</template>
				</Column>					
				<Column :headerStyle="{'min-width': '4rem', 'text-align': 'center'}" :bodyStyle="{'text-align': 'center', overflow: 'visible'}">
					<template #body="{data}">
						<Button type="button" icon="pi pi-file" @click="expand_program(data.program_id)"></Button>
					</template>
				</Column>
				<template #groupheader="slotProps">
					<span class="image-text">{{slotProps.data.secteur}}</span>
				</template>
			</DataTable>
			<div class="table">
				<table id='synoptiquepc' style='visibility: collapse;'>
					<tbody>
						<tr>
							<th>{{ $t('projet') }}</th>
							<th>{{ $t('depositaire') }}</th>
							<th>N°{{ $t('pc') }}</th>
							<th>{{ $t('type') }}</th>
							<th>{{ $t('nature') }}</th>
							<th>{{ $t('statut') }}</th>
							<th>{{ $t('autorisation') }}</th>
							<th>{{ $t('f.u.') }}</th>
							<th>{{ $t('nombre_de_logements_du_projet') }}</th>
							<th>{{ $t('nombre_de_logements_du_pc') }}</th>
							<th>{{ $t('surface') }}</th>
							<th>{{$t('Date MAJ')}}</th>
						</tr>
						<tr v-for="prog in syno"  v-bind:key="prog.id" :class="{trprog:!prog.rupture}" style="height:46px;">
							<td v-if="prog.rupture" class="rupture" colspan=20>{{prog.rupture}}</td>
							<td v-if="!prog.rupture" class="cell" style="text-align: left;">{{prog.nom}} | {{prog.adresse}} - {{prog.ville}}</td>
							<td v-if="!prog.rupture" class="cell" style="text-align: left;">{{prog.nature_depositaire}}</td>
							<td v-if="!prog.rupture" class="cell" style="text-align: left;">{{prog.nopc}}</td>
							<td v-if="!prog.rupture" class="cell" style="text-align: left;">{{prog.npc_libelle}}</td>
							<td v-if="!prog.rupture" class="cell" style="text-align: left;">{{prog.tpl_libelle}}</td>
							<td v-if="!prog.rupture" class="cell" style="text-align: left;">{{prog.statut}}</td>
							<td v-if="!prog.rupture" class="cell" style="text-align: left;">{{prog.date_accord}}</td>
							<td v-if="!prog.rupture" class="cell center">{{prog.forme}}</td>
							<td v-if="!prog.rupture" class="cell center">{{prog.nb_log}}</td>
							<td v-if="!prog.rupture" class="cell center">{{prog.nb_log_pc}}</td>
							<td v-if="!prog.rupture" class="cell center">{{prog.surface_habitation}}</td>
							<td v-if="!prog.rupture" class="cell center">{{prog.date_maj}}</td>
						</tr>
					</tbody>
				</table>
				<br>
				&nbsp;&nbsp; {{ $t('legende_syno_pc') }}
				<br>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import Row from 'primevue/row';
import Button from 'primevue/button';
import SpeedDial from 'primevue/speeddial';

export default {
	name: 'ArraySynoptiquePc',

	data: function() {
		return {
			items: [
				{label: 'Data', icon: 'pi pi-fw pi-download', command:() => {this.$store.dispatch('ExportToXls',{id: 'synoptiquepc', name: 'Synoptique des permis de construire'});} },
				{label: 'Image', icon: 'pi pi-fw pi-image', command:() => {this.$store.dispatch('ExportToPng',{id: 'synopc'});} },
			],
			expandedRowGroups: [],
			selectedPrograms: null,
			secteurencours: '',
			prog_select: false,
			tab: 'mois'
		}
	},

	mounted: function() {
		this.expandedRowGroups = this.app.livedata.tab_sp
	},

	computed: Object.assign(mapGetters([ 'app' ]), {
		synojson:function(){
			
			let tabsyno = [];
			for(let i = 0; i < this.app.livedata.synoptique_pc.length; i++){
				for(let k = 0; k < this.app.livedata.synoptique_pc[i].tableau.length; k++){
					let p = this.app.livedata.synoptique_pc[i].tableau[k]
					p.secteur= this.app.livedata.synoptique_pc[i].secteur
					p.forme = p.forme_urbaine.toUpperCase().substr(0,3)
					tabsyno.push(p);
				}
			}
			return tabsyno
		},

		syno:function(){
			
			let tabsyno = [];
			for(let i = 0; i < this.app.livedata.synoptique_pc.length; i++){
				tabsyno.push({rupture : this.app.livedata.synoptique_pc[i].secteur})
				for(let k = 0; k < this.app.livedata.synoptique_pc[i].tableau.length; k++){
					let p = this.app.livedata.synoptique_pc[i].tableau[k]
					p.rupture= false
					p.forme = p.forme_urbaine.toUpperCase().substr(0,3)
					tabsyno.push(p);
				}
			}
			return tabsyno
		}
	}),

	methods: {
		supprime_selection: function() {
			let tabsyno = [];
			for(let ii = 0; ii < this.app.livedata.synoptique.length; ii++){
				for(let k = 0; k < this.app.livedata.synoptique[ii].tableau.length; k++){
					tabsyno.push(this.app.livedata.synoptique[ii].tableau[k]);
				}
			}
			let tabsyno_pc = [];
			for(let iipc = 0; iipc < this.app.livedata.synoptique_pc.length; iipc++){
				for(let kpc = 0; kpc < this.app.livedata.synoptique_pc[iipc].tableau.length; kpc++){
					tabsyno_pc.push(this.app.livedata.synoptique_pc[iipc].tableau[kpc]);
				}
			}
			let tabsyno_ap = [];
			for(let iiap = 0; iiap < this.app.livedata.synoptique_ap.length; iiap++){
				for(let kap = 0; kap < this.app.livedata.synoptique_ap[iiap].tableau.length; kap++){
					tabsyno_ap.push(this.app.livedata.synoptique_ap[iiap].tableau[kap]);
				}
			}
			let tabsyno_rs = [];
			for(let iirs = 0; iirs < this.app.livedata.synoptique_rs.length; iirs++){
				for(let krs = 0; krs < this.app.livedata.synoptique_rs[iirs].tableau.length; krs++){
					tabsyno_rs.push(this.app.livedata.synoptique_rs[iirs].tableau[krs]);
				}
			}
			let t = []
			let p = []
			let r = []
			let a = []
			for (let i in tabsyno_pc) {
				let b = true
				for (let j in this.selectedPrograms) {
					
					if (this.selectedPrograms[j].program_id == tabsyno_pc[i].program_id) {
						b = false
						break
					}
				}
				if (b) p.push({code : tabsyno_pc[i].program_id})
			}

			for (let ip in tabsyno) {
				t.push({code : tabsyno[ip].program_id})
			}
			for (let ip in tabsyno_ap) {
				a.push({code : tabsyno_ap[ip].program_id})
			}
			for (let ir in tabsyno_rs) {
				r.push({code : tabsyno_rs[ir].program_id})
			}
			this.selectedPrograms = null
			this.$store.dispatch('report_by_id', { programs: t, pc: p, avantpremiere: a, rss: r, view: this.app.source_view})
		},

		expand_program: function(id) {
			this.$store.dispatch('expand_program_from_syno', { id: id, pc: true })
		},
	},

	components: { DataTable, Column, ColumnGroup, Row, Button, SpeedDial }

}
</script>

<style lang="stylus" scoped>
@import '../../../../../assets/css/app'

.rupture
	background secondary
	color white
	font-weight 500 
	text-align center
	text-transform uppercase

.center
	text-align center
.right
	text-align right

th
	background #f0f2f4
	color #666
	text-transform uppercase
	border 1px solid #ccc !important
	
tr:nth-child(even) {background: #f9f9f9}
tr:nth-child(odd) {background: #FFF}
tr
	border-bottom 1px dotted #ccc !important


.prix
	text-align right
	font-weight 800

:deep(.p-progressbar) {
	height: .5rem;
	background-color: #D8DADC;

	.p-progressbar-value {
		background-color: #607D8B;
	}
}
:deep(.p-datatable.synoptique) {
	.p-rowgroup-header{
		background-color: #eeeeee !important;
		color: #333 !important;
		font-size: 1rem;
	}
	.p-rowgroup-header .image-text {
		margin-left: 10px;
	}
    .p-datatable-header {
        padding: 1rem;
        text-align: center;
        font-size: 1rem;
    }
	.p-column-title {
		text-align: center;
		font-size: 0.9rem;
	}
	.p-column-header-content {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.p-datatable-tbody {
		font-size: 0.75rem;
	}
    .p-paginator {
        padding: 1rem;
    }

    .p-datatable-thead > tr > th {
        text-align: center;
    }

    .p-datatable-tbody > tr > td {
        cursor: auto;
		padding: 0.5rem 0.5rem
    }
}
.product-image {
	text-align: center;
    width: auto;
	max-height : 50px
}
</style>
