 <template>
	<div class="stat-box">
		<div class="stat-box-header">
			<div class="flex align-items-center surface-card">
				<div style="width:48px;height:48px;border-radius:10px" class="bg-pink-100 inline-flex align-items-center justify-content-center mr-3">
					<i class="pi pi-list text-pink-600 text-3xl"></i>
				</div>
				<div>
					<span class="text-900 text-xl font-medium mb-2">{{ $t('synoptique') }}</span>
					<p class="mt-1 mb-0 text-600 font-medium text-sm">{{ $t('de_votre_selection_de_programmes') }}</p>
				</div>
				<div class="ml-auto">
					<div class="speeddial-tooltip-demo">
						<SpeedDial className="speeddial-left" :model="items" direction="down" showIcon="pi pi-bars" hideIcon="pi pi-times" buttonClassName="p-button-outlined"/>
					</div>
				</div>
			</div>
		</div>
		<div class="stat-box-content" style="display:flex;flex-wrap: wrap;" id="synoprog">
			<div v-for="prog in syno" v-bind:key="prog.id" class="UnProg">
				<div class="image">
					<img @error="handleImageError" :src="prog.url_photo" alt="">
				</div>
				<p><b>{{prog.nom}}</b></p>
				<p>{{prog.promoteur}}</p>
				<p>{{prog.adresse}}</p><p>{{prog.ville}}</p>
				<p><b>{{ $t('mise_en_vente') }}</b> : {{prog.date_mev}}</p>
				<p><b>{{ $t('stock_initial') }}</b> : {{prog.stock_ini}}</p>
				<p><b>{{ $t('stock_disponible') }}</b> : {{prog.offre}}</p>
				<p><b>{{ $t('rythme_de_vente') }}</b> : {{prog.vente_mois}} {{ $t('vente(s)/mois') }}</p>
				<p><b>{{ $t('part_investisseur') }}</b> : {{prog.inv}}</p>
				<p v-if="prog.redevance"><b>{{ $t('redevance_brs') }}</b> : {{prog.redevance}}</p>
				<p v-if="prog.prix_tva_reduite_hors_parking"><b>{{prog.prix_tva_reduite_hors_parking}}/m²</b> {{ $t('(h.p._tva_reduite)') }}</p>
				<p v-else-if="prog.prix_tva_reduite_avec_parking"><b>{{prog.prix_tva_reduite_avec_parking}}/m²</b> {{ $t('(p.i._tva_reduite)') }}</p>
				<p v-else-if="prog.prix_tva_pleine_aide_hors_parking"><b>{{prog.prix_tva_pleine_aide_hors_parking}}/m²</b> {{ $t('(h.p_tva_pleine_aide)') }}</p>
				<p v-else-if="prog.prix_tva_pleine_aide_avec_parking"><b>{{prog.prix_tva_pleine_aide_avec_parking}}/m²</b> {{ $t('(p.i._tva_pleine_aide)') }}</p>
				<p v-else-if="prog.prix_tva_pleine_libre_hors_parking"><b>{{prog.prix_tva_pleine_libre_hors_parking}}/m²</b> {{ $t('(h.p_tva_pleine)') }}</p>
				<p v-else-if="prog.prix_tva_pleine_libre_avec_parking"><b>{{prog.prix_tva_pleine_libre_avec_parking}}/m²</b> {{ $t('(p.i._tva_pleine)') }}</p>
			</div>			
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import SpeedDial from 'primevue/speeddial';

export default {
	name: 'DivSynoptique',

	data: function() {
		return {
			items: [
				{label: 'Base', icon: 'pi pi-fw pi-database', command:() => {this.getDatabase();} },
				{label: 'Data', icon: 'pi pi-fw pi-download', command:() => {this.$store.dispatch('ExportToXls',{id: 'synoptique', name: 'Synoptique des programmes'});} },
				{label: 'Image', icon: 'pi pi-fw pi-image', command:() => {this.$store.dispatch('ExportToPng',{id: 'synoprog'});} },
			]
			,
			avecbrs: false,
			secteurencours: '',
			prog_select: false,
			tab: 'mois',
			defaultImageUrl: 'https://www.myadequation.fr/EFOCUS./no-camera2.png'
		}
	},

	computed: Object.assign(mapGetters([ 'app' ]), {
		syno:function(){
			
			function enprix(str) {
				if (str) {
					let n = parseFloat(str)
					if (n)
					n = Math.round(n/10)*10		 
					return n.toLocaleString('fr-FR', {style: "decimal", currency: "EUR", maximumFractionDigits : "0"}) + " €"
				}
				return ""
			}
			
			function enprix2(str) {
				if (str) {
					let n = parseFloat(str)
					if (n)
					return n.toString() + " €"
				}
				return ""
			}
			
			let tabsyno = [];
			for(let i = 0; i < this.app.livedata.synoptique.length; i++){
				for(let k = 0; k < this.app.livedata.synoptique[i].tableau.length; k++){
					let p = this.app.livedata.synoptique[i].tableau[k]
					for (let index = 0; index < this.app.programs_filter.length; index++) {
						if (this.app.programs_filter[index].id == p.program_id) {
							p.url_photo =this.app.programs_filter[index].url_photo;
							break;
						}
					}
					p.rupture= false
					p.date_mev = ''
					p.date_liv = ''
					p.date_fin = ''
					p.enqpv = ''
					p.forme = p.forme_urbaine.toUpperCase().substr(0,3)
					p.offre_sur_stock = ['', '', '', '', '']
					p.prix_tva_reduite_hors_parking = ''
					p.prix_tva_reduite_avec_parking = ''
					p.prix_tva_pleine_aide_hors_parking = ''
					p.prix_tva_pleine_aide_avec_parking = ''
					p.prix_tva_pleine_libre_hors_parking = ''
					p.prix_tva_pleine_libre_avec_parking = ''
					if (p.mev)
					p.date_mev = p.mev.substr(4,2) + '/' + p.mev.substr(2,2)
					if (p.liv)
					p.date_liv = p.liv.substr(4,2) + '/' + p.liv.substr(2,2)
					if (p.fin && p.fin!='0')
					p.date_fin = p.fin.substr(4,2) + '/' + p.fin.substr(2,2)
					if (p.qpv!='0')
					p.enqpv = 'X'
					for (let l=0; l<5;l++) {
						if (p.stock_ini_typo[l])
						p.offre_sur_stock[l] = p.offre_typo[l] + '/' + p.stock_ini_typo[l]
					}
					p.inv = p.per_inv.toString() + ' %'
					if (p.prix.tva_reduite.hors_parking) {
						p.prix_tva_reduite_hors_parking =  enprix(p.prix.tva_reduite.hors_parking)
					} 
					if (p.prix.tva_reduite.avec_parking) {
						p.prix_tva_reduite_avec_parking =  enprix(p.prix.tva_reduite.avec_parking)
					}
					if (p.prix.tva_pleine_aide.hors_parking) {
						p.prix_tva_pleine_aide_hors_parking =  enprix(p.prix.tva_pleine_aide.hors_parking)
					}
					if (p.prix.tva_pleine_aide.avec_parking) {
						p.prix_tva_pleine_aide_avec_parking =  enprix(p.prix.tva_pleine_aide.avec_parking)
					}
					if (p.prix.tva_pleine_libre.hors_parking) {
						p.prix_tva_pleine_libre_hors_parking =  enprix(p.prix.tva_pleine_libre.hors_parking)
					}
					if (p.prix.tva_pleine_libre.avec_parking) {
						p.prix_tva_pleine_libre_avec_parking =  enprix(p.prix.tva_pleine_libre.avec_parking)
					}
					if (p.brs_redevance) {
						this.avecbrs = true
					}
					p.redevance =  enprix2(p.brs_redevance)
					
					tabsyno.push(p);
				}
			}
			return tabsyno
		}
	}),

	methods: {
		handleImageError(event) {
			event.target.src = this.defaultImageUrl; // Remplacer par une image par défaut en cas d'erreur
		},
		getDatabase: function() {
			let that = this
			this.$store.dispatch('set_loading', { loading: true })
			let url = ''
			url = this.app.BaseUrlBackend + '/programs/getLots'
			let t=[]
			for(let i = 0; i < this.app.livedata.synoptique.length; i++){
				for(let k = 0; k < this.app.livedata.synoptique[i].tableau.length; k++){
					t.push({"code" : this.app.livedata.synoptique[i].tableau[k].program_id})
				}
			}
			axios.post(url, t, { headers: this.app.HeaderEfocusBack})
			.then(function(response) {
				let exportedFilename = 'lots.csv'					
				let blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
				if (navigator.msSaveBlob) {
					navigator.msSaveBlob(blob, exportedFilename);
				} else {
					let link = document.createElement("a");
					if (link.download !== undefined) {
						let url = URL.createObjectURL(blob);
						link.setAttribute("href", url);
						link.setAttribute("download", exportedFilename);
						link.style.visibility = 'hidden';
						document.body.appendChild(link);
						link.click();
						document.body.removeChild(link);
					}
				}
				that.$store.dispatch('set_loading', { loading: false })
			}).catch(console.error)
		},
		
		supprime_selection: function() {
			let t = []
			let p = []
			let i
			for (i in this.app.programs) {
				if (this.app.programs[i].pc) {
					p.push({code : this.app.programs[i].id})
				} else {
					let eli = document.getElementById(this.app.programs[i].id)
					if (eli) {
						if (eli.className=="checkbox2") {
							t.push({code : this.app.programs[i].id})
						}
					}
				}
			}
//			if (t.length > 2) {
			if (t.length > 0) {
					for (i in this.app.programs) {
					let ell = document.getElementById(this.app.programs[i].id)
					if (ell) {
						if (ell.className!="checkbox2") {
							ell.className="checkbox2"
						}
					}
				}
				this.$store.dispatch('report_by_id', { programs: t, pc: p, view: this.app.source_view})
			} else {
				this.$store.dispatch('add_notification', { status: 'error', message: "Votre sélection de programme doit contenir un minimum de 3 programmes" })
			}
		},

		select_program: function(id) {
			if (document.getElementById(id).className == 'checkbox2 checked') {
				document.getElementById(id).className = 'checkbox2'
			} else {
				document.getElementById(id).className = 'checkbox2 checked'
			}
		},

		expand_program: function(id) {
			if (this.prog_select != id) {
				this.$store.dispatch('expand_program_from_syno', { id: id })
				this.prog_select = id
			} else {
				this.$store.dispatch('expand_program_from_syno', { program: false })
				this.prog_select = false
			}
		},
	}

	,components: { SpeedDial }
}
</script>

<style lang="stylus" scoped>
@import '../../../../../assets/css/app'

.rupture
	background secondary
	color white
	font-weight 500 
	text-align center

.center
	text-align center
.right
	text-align right

th
	background #f0f2f4
	color #666
	text-transform uppercase
	border 1px solid #ccc !important

tr:nth-child(even) {background: #f9f9f9}
tr:nth-child(odd) {background: #FFF}
tr
	border-bottom 1px dotted #ccc !important
th, td
	&:first-child
		padding-left 0px !important

.checkbox2
	position absolute
	right 12px
	top 12px
	width 24px
	height 24px
	border-radius radius
	border 2px solid #ccc
	display block
	cursor pointer
	display flex
	&.disabled
		cursor default
	&.checked
		background-color transparent !important
		.chk .icon
			display block
	&:hover
		border-color palette2
		.chk
			background-color #e5e5e5
	input
		position absolute
		left -12px
		top 0
		display none
	.chk
		float left
		width 24px
		height 24px
		border-radius (radius / 2)
		.icon
			position absolute
			left 2px
			top 2px
			display none
	.text
		display block
		line-height 32px
		white-space nowrap
.UnProg
	margin-bottom 20px
	margin-left 20px
	background #fff
	padding 16px
	width 400px
	.image
		height 160px
		width 300px
		border-radius 12px
		overflow hidden
		margin-bottom 20px
		background #eee url(../../../../../assets/img/no-camera.png) center center no-repeat
		background-size 48px 48px
		img
			position absolute
			left 50%
			transform translateX(-50%)
			height 100%
			width auto

</style>
