<template>
	<div class="stat-box">
		<div class="stat-box-header">
			<div class="flex align-items-center surface-card">
				<div style="width:48px;height:48px;border-radius:10px" class="bg-pink-100 inline-flex align-items-center justify-content-center mr-3">
					<i class="pi pi-list text-pink-600 text-3xl"></i>
				</div>
				<div>
					<span class="text-900 text-xl font-medium mb-2">{{ $t('synoptique') }}</span>
					<p class="mt-1 mb-0 text-600 font-medium text-sm">{{ $t('de_votre_selection_de_programmes') }}</p>
				</div>
				<div class="ml-auto">
					<div class="speeddial-tooltip-demo">
						<SpeedDial className="speeddial-left" :model="items" direction="down" showIcon="pi pi-bars" hideIcon="pi pi-times" buttonClassName="p-button-outlined"/>
					</div>
				</div>
			</div>
		</div>
		<div class="stat-box-content">
			<DataTable showGridlines stripedRows rowGroupMode="subheader" groupRowsBy="secteur" sortMode="single" sortField="secteur" dataKey="id" class="synoptique" id="synoprog" responsiveLayout="scroll"
				:selection.sync="selectedPrograms"
				:value="synojson"
				:sortOrder="1"
				:expandableRowGroups="true"
				:expandedRowGroups.sync="expandedRowGroups"
			>
				<ColumnGroup type="header">
					<Row>
						<Column header="" :rowspan="2" :headerStyle="{width: '30px'}">
							<template #header>
								<div class="table-header">
									<Button icon="pi pi-trash" class="p-button-danger p-button-sm" @click="supprime_selection()" :disabled="!selectedPrograms || !selectedPrograms.length" />
								</div>
							</template>
						</Column>
						<Column field="id" :header="$t('ID')" :rowspan="2" :headerStyle="{width: '80px'}" :sortable="true"/>
						<Column field="nom_promoteur" :header="$t('programme')" :rowspan="2" :sortable="true"/>
						<Column field="forme" :header="$t('f.u.')" :rowspan="2" :headerStyle="{width: '40px'}" />
						<Column field="date_mev" :header="$t('MEV')" :rowspan="2" :headerStyle="{width: '80px'}"/>
						<Column field="date_liv" :header="$t('LIV')" :rowspan="2" :headerStyle="{width: '80px'}"/>
						<Column field="date_fin" :header="$t('FIN')" :rowspan="2" :headerStyle="{width: '80px'}"/>
						<Column :header="$t('offre_/_s.i.')" :colspan="6" />
						<Column field="vente_mois" :header="$t('vm')" :rowspan="2" :headerStyle="{width: '50px'}"/>
						<Column field="enqpv" :header="$t('qpv')" :rowspan="2" :headerStyle="{width: '50px'}"/>
						<Column field="inv" header="Inv." :rowspan="2" :headerStyle="{width: '50px'}"/>
						<Column v-if="tvar" :header="$t('tva_reduite')" :colspan="2" />
						<Column v-if="tvapa" :header="$t('tva_pleine_aidee')" :colspan="2" />
						<Column v-if="tvapl" :header="$t('tva_pleine_libre')" :colspan="2" />
						<Column v-if="avecbrs" :field="$t('redevance')" :header="$t('brs')" :rowspan="2"/>
					</Row>
					<Row>
						<Column field="avancement" :header="$t('avancement')" :sortable="true" :headerStyle="{width: '130px'}"/>
						<Column field="offre_t1" :header="$t('T1')" :styles="{'width': '60px' }"/>
						<Column field="offre_t2" :header="$t('T2')" :styles="{'width': '60px' }"/>
						<Column field="offre_t3" :header="$t('T3')" :styles="{'width': '60px' }"/>
						<Column field="offre_t4" :header="$t('T4')" :styles="{'width': '60px' }"/>
						<Column field="offre_t5" :header="$t('T5')" :styles="{'width': '60px' }"/>
						<Column v-if="tvar" field="prix_tva_reduite_hors_parking" :header="$t('h.p.')" :sortable="true"/>
						<Column v-if="tvar" field="prix_tva_reduite_avec_parking" :header="$t('p.i.')" :sortable="true"/>
						<Column v-if="tvapa" field="prix_tva_pleine_aide_hors_parking" :header="$t('h.p.')" :sortable="true"/>
						<Column v-if="tvapa" field="prix_tva_pleine_aide_avec_parking" :header="$t('p.i.')" :sortable="true"/>
						<Column v-if="tvapl" field="prix_tva_pleine_libre_hors_parking" :header="$t('h.p.')" :sortable="true"/>
						<Column v-if="tvapl" field="prix_tva_pleine_libre_avec_parking" :header="$t('p.i.')" :sortable="true"/>
					</Row>
				</ColumnGroup>
				
				<Column selectionMode="multiple" :styles="{'text-align': 'center', 'padding':'0.3rem' }"></Column>
				<Column field="id" :header="$t('nom')" :styles="{'width': '50px', 'border-left': '0' }">
					<template #body="{data}">
						<div style="width:100%;text-align:center;">
							<div style="border-radius: 50%; height:28px; width:28px;line-height:28px;color:white;background-color: #79397f;">
								{{ data.id }}
							</div>
						</div>
					</template>				
				</Column>
				<Column field="nom_promoteur" :header="$t('nom')" :styles="{'max-width': '300px', 'border-left': '0' }">
					<template #body="{data}">
					<!---->
						<div style="width: 100%;height: 100%;display: flex;align-items: center;cursor: pointer;"  @click="expand_program(data.program_id)">
							<div style="width:80px;"><div style="text-align: center;vertical-align: middle;">
									<ImagePreview @error="handleImageError" :src="data.url_photo" alt="Image" height="40" :imageStyle="{'max-width': '80px' }" />
								</div>
							</div>
							<div><p class="ellipsis-text2"><b>{{ data.nom }}</b></p><p class="ellipsis-text2">{{ data.promoteur }}</p><p class="ellipsis-text2">{{ data.adresse }}</p><!--<p class="ellipsis-text2">{{ data.ville }}</p>--></div>
						</div>
						<!---->
					</template>
				</Column>
				<Column field="forme" :header="$t('f.u.')">
					<template #body="{data}">
						<div class="forme-urbaine" v-if="data.forme_urbaine.toUpperCase()=='COLLECTIF'"><icon file="building" :size="24" style="color:#cccccc" /></div>
						<div class="forme-urbaine" v-else><icon file="home" :size="24" style="color:#cccccc" /></div>
					</template>
				</Column>
				<Column field="date_mev" :header="$t('mev')">
					<template #body="{data}">
						<div style="text-align: center;width:100%">
						{{transformDate(data.date_mev)}}
						</div>
					</template>
				</Column>

				<Column field="date_liv" :header="$t('LIV')">
					<template #body="{data}">
						<div style="text-align: center;width:100%">
							{{transformDate(data.date_liv)}}
						</div>
						</template>
				</Column>

				<Column field="date_fin" :header="$t('FIN')">
					<template #body="{data}">
						<div style="text-align: center;width:100%">
							{{transformDate(data.date_fin)}}
						</div>
					</template>
				</Column>
<!--
				<Column field="date_mev" :header="$t('mev')">
					<template #body="{data}">
						<table class="p-datatable-table">
							<th style="display:none"></th>
							<tbody class="p-datatable-tbody">
								<tr class="" style="border-top:1px dotted #ccc !important">
									<td class="aa" style="width:40px"><b>{{ $t('mev') }}</b></td>
									<td class="aa" style="width:60px">{{data.date_mev}}</td>
								</tr>
								<tr class="">
									<td class="aa"><b>{{$t('LIV')}}</b></td>
									<td class="aa">{{data.date_liv}}</td>
								</tr>
								<tr class="">
									<td class="aa"><b>{{$t('FIN')}}</b></td>
									<td class="aa">{{data.date_fin}}</td>
								</tr>
							</tbody>
						</table>		
					</template>			
				</Column>
-->				
				<Column field="avancement" :header="$t('avancement')" sortable :showFilterMatchModes="false" :styles="{'min-width': '5rem'}">
					<template #body="{data}">
						<div style="text-align: center; padding-bottom: 5px;">{{ data.offre_total }}</div>
						<ProgressBar :value="data.avancement" :showValue="false" />
					</template>
					<template #filter="{filterModel}">
						<Slider v-model="filterModel.value" range class="m-3"></Slider>
						<div class="flex align-items-center justify-content-between px-2">
							<span>{{filterModel.value ? filterModel.value[0] : 0}}</span>
							<span>{{filterModel.value ? filterModel.value[1] : 100}}</span>
						</div>
					</template>
				</Column>
				<Column field="offre_t1" :header="$t('repartition')">
					<template #body="{data}">
						<div style="font-weight: 500;text-align: center;width:100%" v-if="data.stock_ini_typo && data.stock_ini_typo[0]"
							:class="{
								pasdevente : (data.stock_ini_typo && data.offre_typo && data.stock_ini_typo[0] == data.offre_typo[0] && data.stock_ini_typo[0]), 
								pasdoffre : (data.stock_ini_typo && data.offre_typo && !data.offre_typo[0] && data.stock_ini_typo[0])
							}">
							{{ data.offre_typo ? data.offre_typo[0] : '-' }} / {{ data.stock_ini_typo ? data.stock_ini_typo[0] : '-' }}
						</div>
						<div style="text-align: center;width:100%" v-else>-</div>
					</template>
				</Column>
				<Column field="offre_t2" :header="$t('repartition')">
					<template #body="{data}">
						<div style="font-weight: 500;text-align: center;width:100%"  v-if="data.stock_ini_typo && data.stock_ini_typo[1]"
							:class="{
								pasdevente : (data.stock_ini_typo && data.offre_typo && data.stock_ini_typo[1] == data.offre_typo[1] && data.stock_ini_typo[1]), 
								pasdoffre : (data.stock_ini_typo && data.offre_typo && !data.offre_typo[1] && data.stock_ini_typo[1])
							}">
							{{ data.offre_typo ? data.offre_typo[1] : '-' }} / {{ data.stock_ini_typo ? data.stock_ini_typo[1] : '-' }}
						</div>
						<div style="text-align: center;width:100%" v-else>-</div>
					</template>
				</Column>
				<Column field="offre_t3" :header="$t('repartition')">
					<template #body="{data}">
						<div style="font-weight: 500;text-align: center;width:100%"  v-if="data.stock_ini_typo && data.stock_ini_typo[2]"
							:class="{
								pasdevente : (data.stock_ini_typo && data.offre_typo && data.stock_ini_typo[2] == data.offre_typo[2] && data.stock_ini_typo[2]), 
								pasdoffre : (data.stock_ini_typo && data.offre_typo && !data.offre_typo[2] && data.stock_ini_typo[2])
							}">
							{{ data.offre_typo ? data.offre_typo[2] : '-' }} / {{ data.stock_ini_typo ? data.stock_ini_typo[2] : '-' }}
						</div>
						<div style="text-align: center;width:100%" v-else>-</div>
					</template>
				</Column>
				<Column field="offre_t4" :header="$t('repartition')">
					<template #body="{data}">
						<div style="font-weight: 500;text-align: center;width:100%"  v-if="data.stock_ini_typo && data.stock_ini_typo[3]"
							:class="{
								pasdevente : (data.stock_ini_typo && data.offre_typo && data.stock_ini_typo[3] == data.offre_typo[3] && data.stock_ini_typo[3]), 
								pasdoffre : (data.stock_ini_typo && data.offre_typo && !data.offre_typo[3] && data.stock_ini_typo[3])
							}">
							{{ data.offre_typo ? data.offre_typo[3] : '-' }} / {{ data.stock_ini_typo ? data.stock_ini_typo[3] : '-' }}
						</div>
						<div style="text-align: center;width:100%" v-else>-</div>
					</template>
				</Column>
				<Column field="offre_t5" :header="$t('repartition')">
					<template #body="{data}">
						<div style="font-weight: 500;text-align: center;width:100%"  v-if="data.stock_ini_typo && data.stock_ini_typo[4]"
							:class="{
								pasdevente : (data.stock_ini_typo && data.offre_typo && data.stock_ini_typo[4] == data.offre_typo[4] && data.stock_ini_typo[4]), 
								pasdoffre : (data.stock_ini_typo && data.offre_typo && !data.offre_typo[4] && data.stock_ini_typo[4])
							}">
							{{ data.offre_typo ? data.offre_typo[4] : '-' }} / {{ data.stock_ini_typo ? data.stock_ini_typo[4] : '-' }}
						</div>
						<div style="text-align: center;width:100%" v-else>-</div>
					</template>
				</Column>
				<!--
				<Column field="offre_t1" :header="$t('repartition')">
					<template #body="{data}">
						<table role="table" class="p-datatable-table">
							<th style="display:none"></th>
							<thead class="p-datatable-thead">
								<tr>
									<th class="aaa"><div class="p-column-header-content"><span class="p-column-title"></span></div></th>
									<th class="aaa"><div class="p-column-header-content"><span class="p-column-title">{{ $t('t1') }}</span></div></th>
									<th class="aaa"><div class="p-column-header-content"><span class="p-column-title">{{ $t('t2') }}</span></div></th>
									<th class="aaa"><div class="p-column-header-content"><span class="p-column-title">{{ $t('t3') }}</span></div></th>
									<th class="aaa"><div class="p-column-header-content"><span class="p-column-title">{{ $t('t4') }}</span></div></th>
									<th class="aaa"><div class="p-column-header-content"><span class="p-column-title">{{ $t('t5') }}</span></div></th>
								</tr>
							</thead>
							<tbody class="p-datatable-tbody">
								<tr class="">
									<td class="aa">{{ $t('offre') }}</td>
									<td class="aa">{{data.offre_typo[0]}}</td>
									<td class="aa">{{data.offre_typo[1]}}</td>
									<td class="aa">{{data.offre_typo[2]}}</td>
									<td class="aa">{{data.offre_typo[3]}}</td>
									<td class="aa">{{data.offre_typo[4]}}</td>
								</tr>
								<tr class="">
									<td class="aa">{{$t('S.I.')}}</td>
									<td class="aa">{{data.stock_ini_typo[0]}}</td>
									<td class="aa">{{data.stock_ini_typo[1]}}</td>
									<td class="aa">{{data.stock_ini_typo[2]}}</td>
									<td class="aa">{{data.stock_ini_typo[3]}}</td>
									<td class="aa">{{data.stock_ini_typo[4]}}</td>
								</tr>
							</tbody>
						</table>
					</template>
				</Column>
				-->
				<Column field="vente_mois" :header="$t('v/m')" :sortable="true">
					<template #body="{data}">
						<div style="text-align: center;">{{ data.vente_mois }}</div>
					</template>
				</Column>
				<Column field="enqpv" :header="$t('qpv')" :sortable="true">
					<template #body="{data}">
						<div style="text-align: center;">{{ data.enqpv }}</div>
					</template>
				</Column>
				<Column field="inv" :header="$t('Inv.')" :sortable="true">
					<template #body="{data}">
						<Knob v-model="data.per_inv_number" valueTemplate="{value}%" readonly :size="45" />
					</template>
				</Column>
				<Column v-if="tvar" field="prix_tva_reduite_hors_parking" :header="$t('h.p.')">
					<template #body="{data}">
						<div class="prix">{{ data.prix_tva_reduite_hors_parking }}</div>
					</template>
				</Column>
				<Column v-if="tvar" field="prix_tva_reduite_avec_parking" :header="$t('p.i.')">
					<template #body="{data}">
						<div class="prix">{{ data.prix_tva_reduite_avec_parking }}</div>
					</template>
				</Column>
				<Column v-if="tvapa" field="prix_tva_pleine_aide_hors_parking" :header="$t('h.p.')">
					<template #body="{data}">
						<div class="prix">{{ data.prix_tva_pleine_aide_hors_parking }}</div>
					</template>
				</Column>
				<Column v-if="tvapa" field="prix_tva_pleine_aide_avec_parking" :header="$t('p.i.')">
					<template #body="{data}">
						<div class="prix">{{ data.prix_tva_pleine_aide_avec_parking }}</div>
					</template>
				</Column>
				<Column v-if="tvapl" field="prix_tva_pleine_libre_hors_parking" :header="$t('h.p.')">
					<template #body="{data}">
						<div class="prix">{{ data.prix_tva_pleine_libre_hors_parking }}</div>
					</template>
				</Column>
				<Column v-if="tvapl" field="prix_tva_pleine_libre_avec_parking" :header="$t('p.i.')">
					<template #body="{data}">
						<div class="prix">{{ data.prix_tva_pleine_libre_avec_parking }}</div>
					</template>
				</Column>
				<Column v-if="avecbrs" :field="$t('redevance')" :header="$t('redevance_brs')" :sortable="true">
					<template #body="{data}">
						<div class="prix">{{ enprix2(data.redevance) }}</div>
					</template>
				</Column>
				<template #groupheader="slotProps">
					<span class="image-text">{{slotProps.data.secteur}}</span>
				</template>
			</DataTable>
			<br>
			&nbsp;&nbsp; {{ $t('legende_syno') }}
			<br>
			<table id='synoptique' style='visibility: collapse;'>
				<tbody>
					<tr>
						<th>{{ $t('programme') }}</th>
						<th>{{ $t('f.u.') }}</th>
						<th>{{$t('MEV')}}</th>
						<th>{{$t('LIV')}}</th>
						<th>{{$t('FIN')}}</th>
						<th>{{$t('Total')}}</th>
						<th>{{ $t('t1') }}</th>
						<th>{{ $t('t2') }}</th>
						<th>{{ $t('t3') }}</th>
						<th>{{ $t('t4') }}</th>
						<th>{{ $t('t5') }}</th>
						<th>{{ $t('v/m') }}</th>
						<th>{{ $t('qpv') }}</th>
						<th>{{$t('Inv.')}}</th>
						<th>{{$t('TVA Réduite H.P.')}}</th>
						<th>{{$t('TVA Réduite P.I.')}}</th>
						<th>{{$t('TVA Pleine Aidée H.P.')}}</th>
						<th>{{$t('TVA Pleine Aidée P.I.')}}</th>
						<th>{{$t('TVA Pleine Libre H.P.')}}</th>
						<th>{{$t('TVA Pleine Libre P.I.')}}</th>
						<th>{{$t('Redevance BRS')}}</th>
					</tr>
					<tr v-for="prog in syno"  v-bind:key="prog.id" :class="{trprog:!prog.rupture}" style="height:46px;">
						<td v-if="!prog.rupture">{{prog.nom}} | {{prog.promoteur}} | {{prog.adresse}} - {{prog.ville}}</td>
						<td v-if="!prog.rupture">{{prog.forme}}</td>
						<td v-if="!prog.rupture">{{prog.date_mev}}</td>
						<td v-if="!prog.rupture">{{prog.date_liv}}</td>
						<td v-if="!prog.rupture">{{prog.date_fin}}</td>
						<td v-if="!prog.rupture">{{prog.offre}} / {{prog.stock_ini}}</td>
						<td v-if="!prog.rupture">{{prog.offre_sur_stock[0]}}</td>
						<td v-if="!prog.rupture">{{prog.offre_sur_stock[1]}}</td>
						<td v-if="!prog.rupture">{{prog.offre_sur_stock[2]}}</td>
						<td v-if="!prog.rupture">{{prog.offre_sur_stock[3]}}</td>
						<td v-if="!prog.rupture">{{prog.offre_sur_stock[4]}}</td>
						<td v-if="!prog.rupture">{{prog.vente_mois}}</td>
						<td v-if="!prog.rupture">{{prog.enqpv}}</td>
						<td v-if="!prog.rupture">{{prog.inv}}</td>
						<td v-if="!prog.rupture">{{prog.prix_tva_reduite_hors_parking}}</td>
						<td v-if="!prog.rupture">{{prog.prix_tva_reduite_avec_parking}}</td>
						<td v-if="!prog.rupture">{{prog.prix_tva_pleine_aide_hors_parking}}</td>
						<td v-if="!prog.rupture">{{prog.prix_tva_pleine_aide_avec_parking}}</td>
						<td v-if="!prog.rupture">{{prog.prix_tva_pleine_libre_hors_parking}}</td>
						<td v-if="!prog.rupture">{{prog.prix_tva_pleine_libre_avec_parking}}</td>
						<td v-if="!prog.rupture && avecbrs">{{prog.redevance}}</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div style="width:100%; height: 2px; border-bottom: 1px solid #CCC;margin-top:10px; margin-bottom:10px;"></div>
		<div style="width: 100%;height: auto;display: flex;justify-content: space-around;flex-direction: row;flex-wrap: wrap;">
			<div class="TailleCarte">
				<div id="map_syno">

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import Row from 'primevue/row';
import ProgressBar from 'primevue/progressbar';
import Slider from 'primevue/slider';
// eslint-disable-next-line
import Button from 'primevue/button';
// eslint-disable-next-line
import ImagePreview from 'primevue/imagepreview';
import SpeedDial from 'primevue/speeddial';
import Knob from 'primevue/knob';
import L from 'leaflet'
// eslint-disable-next-line
import map_helper from '../../../../../helpers/map_helper'

export default {
	name: 'ArraySynoptique',

	data: function() {
		return {
			items: [
				{label: 'Base', icon: 'pi pi-fw pi-database', command:() => {this.getDatabase();} },
				{label: 'Data', icon: 'pi pi-fw pi-download', command:() => {this.$store.dispatch('ExportToXls',{id: 'synoptique', name: 'Synoptique des programmes'});} },
				{label: 'Image', icon: 'pi pi-fw pi-image', command:() => {this.$store.dispatch('ExportToPng',{id: 'synoprog'});} },
			]
			,
			avecbrs: false,
			tvapl : false,
			tvapa : false,
			tvar : false,
			secteurencours: '',
			prog_select: false,
			tab: 'mois',
			expandedRowGroups: [],
			selectedPrograms: null,
			allchecked: false,
			LayerMapnik: false,
			LayerImagery: false,
			LayerDark: false,
			map_syno: false,
			markers_layer: false,
			map_poi: false,
			poi_layer: false,
			currentPOIs: {},
			group_poi: [],
			options: [
				{
					id: 'Transports',
					label: 'Transports',
					children: [ {
							id: 'Bus',
							label: 'Bus',
						}, {
							id: 'Tramway',
							label: 'Tramway',
						}, {
							id: 'Métro',
							label: 'Métro',
						}, 
						{
							id: 'Gare',
							label: 'Gare',
						}, {
							id: 'Aéroport',
							label: 'Aéroport',
						}
					],
				},
				{
					id: 'Education',
					label: 'Education',
						children: [ {
							id: 'Collége',
							label: 'Collége',
						}, {
							id: 'Ecole élémentaire',
							label: 'Ecole élémentaire',
						}, {
							id: 'Ecole maternelle',
							label: 'Ecole maternelle',
						}, {
							id: 'Lycée',
							label: 'Lycée',
						}, {
							id: 'Section d\'enseignement',
							label: 'Section d\'enseignement',
						}, {
							id: 'Enseignement spécialisé',
							label: 'Enseignement spécialisé',
						}, {
							id: 'Enseignement supérieur',
							label: 'Enseignement supérieur',
						},
					],
				},
				{
					id: 'Alimentaire',
					label: 'Alimentaire',
						children: [ {
							id: 'Boulangerie',
							label: 'Boulangerie',
						}, {
							id: 'Epicerie',
							label: 'Epicerie',
						}, {
							id: 'Supermarché',
							label: 'Supermarché',
						}, {
							id: 'Hypermarché',
							label: 'Hypermarché',
						}, {
							id: 'Boucher',
							label: 'Boucher',
						}, {
							id: 'Primeur',
							label: 'Primeur',
						}
					],
				},
				{
					id: 'Café - Hôtel - Restaurant',
					label: 'Café - Hôtel - Restaurant',
						children: [ {
							id: 'Hôtel',
							label: 'Hôtel',
						}, {
							id: 'Bar',
							label: 'Bar',
						}, {
							id: 'Café',
							label: 'Café',
						}, {
							id: 'Restaurant',
							label: 'Restaurant',
						}, {
							id: 'Club',
							label: 'Club',
						}
					],
				},
				{
					id: 'Achats de proximité',
					label: 'Achats de proximité',
						children: [ {
							id: 'Magasin de jouets',
							label: 'Magasin de jouets',
						}, {
							id: 'Fleuriste',
							label: 'Fleuriste',
						}, {
							id: 'Librairie',
							label: 'Librairie',
						}, {
							id: 'Marchand de journaux',
							label: 'Marchand de journaux',
						}, {
							id: 'Tabac - Presse',
							label: 'Tabac - Presse',
						}
					],
				},
				{
					id: 'Shopping',
					label: 'Shopping',
						children: [{
							id: 'Magasin de bricolage',
							label: 'Magasin de bricolage',
						}, {
							id: 'Jardinerie',
							label: 'Jardinerie',
						}, {
							id: 'Magasin de meubles',
							label: 'Magasin de meubles',
						}, {
							id: 'Magasin de vêtements',
							label: 'Magasin de vêtements',
						}, {
							id: 'Friperie',
							label: 'Friperie',
						}, {
							id: 'Magasin de chaussures',
							label: 'Magasin de chaussures',
						}, {
							id: 'Bijouterie',
							label: 'Bijouterie',
						}, {
							id: 'Vendeur de vélo',
							label: 'Vendeur de vélo',
						}, {
							id: 'Magasin de sport',
							label: 'Magasin de sport',
						}
					],
				},
				{
					id: 'Equipement public',
					label: 'Equipement public',
						children: [ {
							id: 'Police',
							label: 'Police',
						}, {
							id: 'Ambassade',
							label: 'Ambassade',
						}, {
							id: 'Hôtel de ville',
							label: 'Hôtel de ville',
						}, {
							id: 'Tribunal',
							label: 'Tribunal',
						}, {
							id: 'Bureau de poste',
							label: 'Bureau de poste',
						}
					],
				},
				{
					id: 'Santé',
					label: 'Santé',
						children: [ {
							id: 'Hôpital',
							label: 'Hôpital',
						}, {
							id: 'Pharmacie',
							label: 'Pharmacie',
						}, {
							id: 'Opticien',
							label: 'Opticien',
						}, {
							id: 'Dentiste',
							label: 'Dentiste',
						}, {
							id: 'Médecin',
							label: 'Médecin',
						}
					],
				},
				{
					id: 'Beauté',
					label: 'Beauté',
						children: [{
							id: 'Cosmétique',
							label: 'Cosmétique',
						}, {
							id: 'Coiffeur',
							label: 'Coiffeur',
						}, {
							id: 'Salon de beauté',
							label: 'Salon de beauté',
						}
					],
				},
				{
					id: 'Services',
					label: 'Services',
						children: [ {
							id: 'Distributeurs',
							label: 'Distributeurs',
						}, {
							id: 'Location de voiture',
							label: 'Location de voiture',
						}, {
							id: 'Vétérinaire',
							label: 'Vétérinaire',
						}, {
							id: 'Vendeur auto',
							label: 'Vendeur auto',
						}, {
							id: 'Agence de voyage',
							label: 'Agence de voyage',
						}, {
							id: 'Garage',
							label: 'Garage',
						}, {
							id: 'Blanchisserie',
							label: 'Blanchisserie',
						}, {
							id: 'Banque',
							label: 'Banque',
						}
					],
				}
			],
			poiList: [],
			poiEducation: [],
			poiTransport: [],	
			circle: null,	
			defaultImageUrl: 'https://www.myadequation.fr/EFOCUS./no-camera2.png'
		}
	},

	mounted: function() {
		this.expandedRowGroups = this.app.livedata.tab_s
		let accessToken = 'pk.eyJ1Ijoic2ViZHVwb250IiwiYSI6ImNrdmdlcDMxaTAxdmUydXAzcW5yNDRpZmQifQ.8AvKt1ETauEmOWnK0TjNSg';
		this.LayerMapnik_A = L.tileLayer('https://api.mapbox.com/styles/v1/sebdupont/cl8x860c1000l15qi6h68eupp/tiles/512/{z}/{x}/{y}@2x?access_token=' + accessToken, {
			attribution: '© <a target="_blank" href="https://www.mapbox.com/feedback/">Mapbox</a> © <a target="_blank" href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
			tileSize: 512,
			zoomOffset: -1
		});
		this.LayerMapnik_B = L.tileLayer('https://api.mapbox.com/styles/v1/sebdupont/cl8x860c1000l15qi6h68eupp/tiles/512/{z}/{x}/{y}@2x?access_token=' + accessToken, {
			attribution: '© <a target="_blank" href="https://www.mapbox.com/feedback/">Mapbox</a> © <a target="_blank" href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
			tileSize: 512,
			zoomOffset: -1
		});

		let lb = this.LayerMapnik_B
		this.map_syno = L.map('map_syno', {
			center: [this.app.adr_init.latlng.lat, this.app.adr_init.latlng.lng],
			attribution: '<a target="_blank" href="https://leaflet.js/">Leaflet</a> ©',
			minZoom: 12,
			zoom : this.$store.map_osm.getZoom(),
			zoomControl: true,
			editable: true,
			layers: [ lb ]
		})
		L.control.scale().addTo(this.map_syno);
		if (this.markers_layer) {
			this.map_syno.removeLayer(this.markers_layer)
			this.markers_layer = L.featureGroup().addTo(this.map_syno)
		} else {
			this.markers_layer = L.featureGroup().addTo(this.map_syno)
		}

		let classname = 'map-icon-prj';
		let decal = [20, 40];
		let iconMrk = L.divIcon({ className: classname, html: '<div class="pin_monprojet"><div class="label"></div></div>', iconAnchor: decal });
		let marker = L.marker([this.app.mrk.lat, this.app.mrk.lng],{ icon: iconMrk, title: 'Mon projet' });
		marker.addTo(this.markers_layer)

		for(let si = 0; si < this.app.livedata.synoptique.length; si++){
			for(let j = 0; j < this.app.livedata.synoptique[si].tableau.length; j++){
				let program = false
				for (let i in this.app.programs_filter) {
					if (this.app.programs_filter[i].id == this.app.livedata.synoptique[si].tableau[j].program_id)
					{
						program = this.app.programs_filter[i]
						break;
					}
				}
				if (program) {
					let pxm2 = 0
					if (program.tva_pleine_libre_prix_m2_parking_avec) {
						pxm2 = program.tva_pleine_libre_prix_m2_parking_avec
					} else {
						if (program.tva_pleine_libre_prix_m2_parking_sans) {
							pxm2 = program.tva_pleine_libre_prix_m2_parking_sans
						} else {
							if (program.tva_pleine_aide_prix_m2_parking_avec) {
								pxm2 = program.tva_pleine_aide_prix_m2_parking_avec
							} else {
								if (program.tva_pleine_aide_prix_m2_parking_sans) {
									pxm2 = program.tva_pleine_aide_prix_m2_parking_sans
								} else {
									if (program.tva_reduite_prix_m2_parking_avec) {
										pxm2 = program.tva_reduite_prix_m2_parking_avec
									} else {
										if (program.tva_reduite_prix_m2_parking_sans) {
											pxm2 = program.tva_reduite_prix_m2_parking_sans
										}
									}
								}
							}
						}
					}
					let nogamme = 'milieu'
					let color = '#ee8d3e'
					if (program.location.lat && program.location.lon) {
						if (pxm2 < this.app.echelleprix_1) {
							nogamme = 'entree'
							color = '#fcc434'
						} else {
							if (pxm2 < this.app.echelleprix_2) {
								nogamme = 'milieu'
								color = '#ee8d3e'
							} else {
								nogamme = 'haut'
								color = '#d74a22'
							}
						}
						color = '#79397f'
						let icon = L.divIcon({ className: nogamme, iconSize: [24, 24], iconAnchor: [12, 12], html: '<div style="background-color: ' + color + ';border-radius: 50%;display: flex;justify-content: center;align-items: center;height: 24px;width: 24px;color: white;font-size: 11px;"><span>' + this.app.livedata.synoptique[si].tableau[j].id + '</span></div>' });
						let marker = L.marker([program.location.lat, program.location.lon], { icon: icon, title: program.nom + ', ' + program.promoteur });
						//let marker = map_helper.build_marker({ that: this, program: program, pin_type: this.app.pin_type, gamme : nogamme })
						if (marker) {							
							marker.addTo(this.markers_layer)
						}
					}
				}
			}
		}
		
		this.circle = L.circle([this.app.mrk.lat, this.app.mrk.lng], { radius: 100,color: 'white',fillColor: 'white',fillOpacity: 0.1, opacity: 0.1}).addTo(this.markers_layer);
		this.map_syno.fitBounds(this.markers_layer.getBounds());
	
	},

	computed: Object.assign(mapGetters([ 'app' ]), {
		synojson:function(){
			let tabsyno = [];
			this.avecbrs = false;
			this.tvapl = false;
			this.tvapa = false;
			this.tvar = false;
			for(let i = 0; i < this.app.livedata.synoptique.length; i++){
				for(let k = 0; k < this.app.livedata.synoptique[i].tableau.length; k++){
					let p = this.app.livedata.synoptique[i].tableau[k]
					p.id = this.app.livedata.synoptique[i].tableau[k].id
					p.secteur = this.app.livedata.synoptique[i].secteur
					p.date_mev = ''
					p.date_liv = ''
					p.date_fin = ''
					p.enqpv = ''
					p.forme = p.forme_urbaine.toUpperCase().substr(0,3)
					p.offre_sur_stock = ['', '', '', '', '']
					p.prix_tva_reduite_hors_parking = ''
					p.prix_tva_reduite_avec_parking = ''
					p.prix_tva_pleine_aide_hors_parking = ''
					p.prix_tva_pleine_aide_avec_parking = ''
					p.prix_tva_pleine_libre_hors_parking = ''
					p.prix_tva_pleine_libre_avec_parking = ''
					if (p.mev)
					p.date_mev = p.mev.substr(4,2) + '/' + p.mev.substr(2,2)
					if (p.liv)
					p.date_liv = p.liv.substr(4,2) + '/' + p.liv.substr(2,2)
					if (p.fin && p.fin!='0')
					p.date_fin = p.fin.substr(4,2) + '/' + p.fin.substr(2,2)
					if (p.qpv!='0')
					p.enqpv = 'X'
					for (let l=0; l<5;l++) {
						if (p.stock_ini_typo[l])
						p.offre_sur_stock[l] = p.offre_typo[l] + '/' + p.stock_ini_typo[l]
					}
					p.inv = p.per_inv
					p.per_inv_number = Math.round(p.per_inv)
					if (p.prix.tva_reduite.hors_parking) {
						p.prix_tva_reduite_hors_parking =  p.prix.tva_reduite.hors_parking
						this.tvar = true
					} 
					if (p.prix.tva_reduite.avec_parking) {
						p.prix_tva_reduite_avec_parking =  p.prix.tva_reduite.avec_parking
						this.tvar = true
					}
					if (p.prix.tva_pleine_aide.hors_parking) {
						p.prix_tva_pleine_aide_hors_parking =  p.prix.tva_pleine_aide.hors_parking
						this.tvapa = true
					}
					if (p.prix.tva_pleine_aide.avec_parking) {
						p.prix_tva_pleine_aide_avec_parking =  p.prix.tva_pleine_aide.avec_parking
						this.tvapa = true
					}
					if (p.prix.tva_pleine_libre.hors_parking) {
						p.prix_tva_pleine_libre_hors_parking =  p.prix.tva_pleine_libre.hors_parking
						this.tvapl = true
					}
					if (p.prix.tva_pleine_libre.avec_parking) {
						p.prix_tva_pleine_libre_avec_parking =  p.prix.tva_pleine_libre.avec_parking
						this.tvapl = true
					}
					if (p.brs_redevance) {
						this.avecbrs = true
					}
					p.offre_total = p.offre + '/' + p.stock_ini
					p.avancement = (p.stock_ini - p.offre)/p.stock_ini * 100
					p.offre_t1 = p.offre_sur_stock[0]
					p.offre_t2 = p.offre_sur_stock[1]
					p.offre_t3 = p.offre_sur_stock[2]
					p.offre_t4 = p.offre_sur_stock[3]
					p.offre_t5 = p.offre_sur_stock[4]
					p.redevance =  p.brs_redevance
					p.nom_promoteur = p.nom + " - " + p.promoteur
					p.adresse_ville = p.adresse + " - " + p.ville
					tabsyno.push(p);
				}
			}
			return tabsyno
		},

		syno:function(){
			
			let tabsyno = [];
			for(let i = 0; i < this.app.livedata.synoptique.length; i++){
				tabsyno.push({rupture : this.app.livedata.synoptique[i].secteur})
				for(let k = 0; k < this.app.livedata.synoptique[i].tableau.length; k++){
					let p = this.app.livedata.synoptique[i].tableau[k]
					p.rupture= false
					p.date_mev = ''
					p.date_liv = ''
					p.date_fin = ''
					p.enqpv = ''
					p.forme = p.forme_urbaine.toUpperCase().substr(0,3)
					p.offre_sur_stock = ['', '', '', '', '']
					p.prix_tva_reduite_hors_parking = ''
					p.prix_tva_reduite_avec_parking = ''
					p.prix_tva_pleine_aide_hors_parking = ''
					p.prix_tva_pleine_aide_avec_parking = ''
					p.prix_tva_pleine_libre_hors_parking = ''
					p.prix_tva_pleine_libre_avec_parking = ''
					if (p.mev)
					p.date_mev = p.mev.substr(4,2) + '/' + p.mev.substr(2,2)
					if (p.liv)
					p.date_liv = p.liv.substr(4,2) + '/' + p.liv.substr(2,2)
					if (p.fin && p.fin!='0')
					p.date_fin = p.fin.substr(4,2) + '/' + p.fin.substr(2,2)
					if (p.qpv!='0')
					p.enqpv = 'X'
					for (let l=0; l<5;l++) {
						if (p.stock_ini_typo[l])
						p.offre_sur_stock[l] = p.offre_typo[l] + '/' + p.stock_ini_typo[l]
					}
					p.inv = p.per_inv.toString() + ' %'
					if (p.prix.tva_reduite.hors_parking) {
						p.prix_tva_reduite_hors_parking =  this.enprix(p.prix.tva_reduite.hors_parking)
					} 
					if (p.prix.tva_reduite.avec_parking) {
						p.prix_tva_reduite_avec_parking =  this.enprix(p.prix.tva_reduite.avec_parking)
					}
					if (p.prix.tva_pleine_aide.hors_parking) {
						p.prix_tva_pleine_aide_hors_parking =  this.enprix(p.prix.tva_pleine_aide.hors_parking)
					}
					if (p.prix.tva_pleine_aide.avec_parking) {
						p.prix_tva_pleine_aide_avec_parking =  this.enprix(p.prix.tva_pleine_aide.avec_parking)
					}
					if (p.prix.tva_pleine_libre.hors_parking) {
						p.prix_tva_pleine_libre_hors_parking =  this.enprix(p.prix.tva_pleine_libre.hors_parking)
					}
					if (p.prix.tva_pleine_libre.avec_parking) {
						p.prix_tva_pleine_libre_avec_parking =  this.enprix(p.prix.tva_pleine_libre.avec_parking)
					}
					if (p.brs_redevance) {
						this.avecbrs = true
					}
					p.redevance =  this.enprix2(p.brs_redevance)
					
					tabsyno.push(p);
				}
			}
			return tabsyno
		}
	}),

	methods: {
		transformDate: function(dateString) {
			// Séparer le mois et l'année
			if (!dateString) return ""
			const [month, year] = dateString.split('/');

			// Liste des noms des mois en français
			const mois = ['jan.', 'fév.', 'mars', 'avr.', 'mai', 'juin', 'juil.', 'août', 'sept.', 'oct.', 'nov.', 'déc.'];

			// Convertir le mois en index et l'année en format 20XX
			const moisNom = mois[parseInt(month, 10) - 1];  // On soustrait 1 car les mois sont indexés de 0 à 11
			const fullYear = `20${year}`;

			return `${moisNom} ${fullYear}`;
		},
		handleImageError(event) {
			event.target.src = this.defaultImageUrl; // Remplacer par une image par défaut en cas d'erreur
		},

		enprix: function (str) {
			if (str) {
				let n = parseFloat(str)
				if (n)
				n = Math.round(n/10)*10		 
				return n.toLocaleString('fr-FR', {style: "decimal", currency: "EUR", maximumFractionDigits : "0"}) + " €"
			}
			return ""
		},

		enprix2: function (str) {
			if (str) {
				let n = parseFloat(str)
				if (n)
				return n.toString() + " €"
			}
			return ""
		},

		getDatabase: function() {
			let that = this
			this.$store.dispatch('set_loading', { loading: true })

			let url = ''
			url = this.app.BaseUrlBackend + '/programs/getLots'
			let t=[]
			for(let i = 0; i < this.app.livedata.synoptique.length; i++){
				for(let k = 0; k < this.app.livedata.synoptique[i].tableau.length; k++){
					t.push({"code" : this.app.livedata.synoptique[i].tableau[k].program_id})
				}
			}
			axios.post(url, t, { headers: this.app.HeaderEfocusBack})
			.then(function(response) {
				let exportedFilename = 'lots.csv'					
				let blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
				if (navigator.msSaveBlob) {
					navigator.msSaveBlob(blob, exportedFilename);
				} else {
					let link = document.createElement("a");
					if (link.download !== undefined) {
						let url = URL.createObjectURL(blob);
						link.setAttribute("href", url);
						link.setAttribute("download", exportedFilename);
						link.style.visibility = 'hidden';
						document.body.appendChild(link);
						link.click();
						document.body.removeChild(link);
					}
				}
				that.$store.dispatch('set_loading', { loading: false })
			}).catch(console.error)
		},
		
		supprime_selection: function() {
			let tabsyno = [];
			for(let ii = 0; ii < this.app.livedata.synoptique.length; ii++){
				for(let k = 0; k < this.app.livedata.synoptique[ii].tableau.length; k++){
					tabsyno.push(this.app.livedata.synoptique[ii].tableau[k]);
				}
			}
			let tabsyno_pc = [];
			for(let iipc = 0; iipc < this.app.livedata.synoptique_pc.length; iipc++){
				for(let kpc = 0; kpc < this.app.livedata.synoptique_pc[iipc].tableau.length; kpc++){
					tabsyno_pc.push(this.app.livedata.synoptique_pc[iipc].tableau[kpc]);
				}
			}
			let tabsyno_ap = [];
			for(let iiap = 0; iiap < this.app.livedata.synoptique_ap.length; iiap++){
				for(let kap = 0; kap < this.app.livedata.synoptique_ap[iiap].tableau.length; kap++){
					tabsyno_ap.push(this.app.livedata.synoptique_ap[iiap].tableau[kap]);
				}
			}
			let tabsyno_rs = [];
			for(let iirs = 0; iirs < this.app.livedata.synoptique_rs.length; iirs++){
				for(let krs = 0; krs < this.app.livedata.synoptique_rs[iirs].tableau.length; krs++){
					tabsyno_rs.push(this.app.livedata.synoptique_rs[iirs].tableau[krs]);
				}
			}
			
			let t = []
			let p = []
			let r = []
			let a = []

			if (tabsyno.length - this.selectedPrograms.length > 2) {
				for (let i in tabsyno) {
					let b = true
					for (let j in this.selectedPrograms) {
						
						if (this.selectedPrograms[j].program_id == tabsyno[i].program_id) {
							b = false
							break
						}
					}
					if (b) t.push({code : tabsyno[i].program_id})
				}

				for (let ip in tabsyno_pc) {
					p.push({code : tabsyno_pc[ip].program_id})
				}
				for (let ir in tabsyno_rs) {
					r.push({code : tabsyno_rs[ir].program_id})
				}
				for (let ip in tabsyno_ap) {
					a.push({code : tabsyno_ap[ip].program_id})
				}
				this.selectedPrograms = null
				this.$store.dispatch('report_by_id', { programs: t, pc: p, avantpremiere: a, rss: r, view: this.app.source_view})
			} else {
				this.$store.dispatch('add_notification', { status: 'error', message: "Votre sélection de programme doit contenir un minimum de 3 programmes" })
			}
		},

		select_program: function(id) {
			if (document.getElementById(id).className == 'checkbox2 checked') {
				document.getElementById(id).className = 'checkbox2'
			} else {
				document.getElementById(id).className = 'checkbox2 checked'
			}
		},

		expand_program: function(id) {
			this.$store.dispatch('expand_program_from_syno', { id: id })
		},
	},
	// eslint-disable-next-line
	components: { DataTable, Column, ColumnGroup, Row, ProgressBar, Slider, Button, ImagePreview, SpeedDial, Knob }
}

</script>

<style lang="stylus" scoped>
@import '../../../../../assets/css/app'

.TailleCarte
	width 800px
	height 620px
	margin-bottom 10px

#map_syno
	width 100%
	height 100%

#map_poi
	width 100%
	height 100%


.rupture
	background secondary
	color white
	font-weight 500 
	text-align center

.center
	text-align center
.right
	text-align right

th
	background #f0f2f4
	color #666
	text-transform uppercase
	border 1px solid #ccc !important

tr:nth-child(even) {background: #f9f9f9}
tr:nth-child(odd) {background: #FFF}
tr
	border-bottom 1px dotted #ccc !important
th, td
	&:first-child
		padding-left 0px !important

.checkbox2
	position absolute
	right 12px
	top 12px
	width 24px
	height 24px
	border-radius radius
	border 2px solid #ccc
	display block
	cursor pointer
	display flex
	&.disabled
		cursor default
	&.checked
		background-color transparent !important
		.chk .icon
			display block
	&:hover
		border-color palette2
		.chk
			background-color #e5e5e5
	input
		position absolute
		left -12px
		top 0
		display none
	.chk
		float left
		width 24px
		height 24px
		border-radius (radius / 2)
		.icon
			position absolute
			left 2px
			top 2px
			display none
	.text
		display block
		line-height 32px
		white-space nowrap
.prix
	text-align right
	font-weight 800


.custom-marker {
	background-color: red;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 30px;
	width: 30px;
	color: white;
	font-size: 12px;
}

:deep(.p-progressbar) {
	height: .5rem;
	background-color: #D8DADC;

	.p-progressbar-value {
		background-color: #607D8B;
	}
}
:deep(.p-datatable.synoptique) {
	.pasdevente {
		color: red;
	}
	.pasdoffre {
		color: #22c55e;
	}
	.p-rowgroup-header{
		background-color: #eeeeee !important;
		color: #333 !important;
		font-size: 1rem;
	}
	.p-rowgroup-header .image-text {
		margin-left: 10px;
	}
    .p-datatable-header {
        padding: 1rem;
        text-align: center;
        font-size: 1rem;
    }
	.p-column-title {
		text-align: center;
		font-size: 0.9rem;
	}
	.p-column-header-content {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.p-datatable-tbody {
		font-size: 0.75rem;
	}
    .p-paginator {
        padding: 1rem;
    }

    .p-datatable-thead > tr > th {
        text-align: center;
		padding: 0.5rem 0.5rem
		font-size: 0.75rem;
    }

    .p-datatable-tbody > tr > td {
        cursor: auto;
		padding: 0.5rem 0.5rem
    }
}
.product-image {
	text-align: center;
    width: auto;
	max-height : 50px
}
.ellipsis-text2 {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.aa {
	padding: 0.2rem 0.2rem !important;
	text-align: center !important;
}
.aaa {
	padding: 0.2rem 0.2rem !important;
	text-align: center;
}
.table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

</style>
